import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import UnderlinedLink from '../UnderlinedLink'

const Intro = styled.section`
  padding: 40px 21px 52px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 500px) {
    padding: 58px 8.3% 68px;
  }

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
  }

  & div {
    &:first-child {
      @media screen and (min-width: 1200px) {
        margin-right: 12%;
      }

      @media screen and (min-width: 1440px) {
        margin-right: 0%;
      }

      h2 {
        ${(props) => `
          color: ${props.color};
        `}

        font-size: 50px;
        font-size: 3.125rem;

        @media screen and (min-width: 768px) {
          font-size: 50px;
          font-size: 3.375rem;
        }

        @media screen and (min-width: 1440px) {
          font-size: 100px;
          font-size: 6.25rem;
        }
      }
    }
  }

  & div {
    &:last-child {
      @media screen and (min-width: 1440px) {
        width: 100%;
      }

      p {
        @media screen and (min-width: 1440px) {
          font-size: 22px;
          font-size: 1.375rem;
        }
      }
    }
  }
`

const RaceIntro = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block
  const { link_text } = settings
  const { colors } = props

  return (
    <Intro color={colors.tertiary}>
      <div>
        <h2>{settings.heading}</h2>
      </div>

      <div>
        {settings.copy && (
          <div
            dangerouslySetInnerHTML={{
              __html: settings.copy.replace(
                /(\/media)+/g,
                `${context.root}/media`
              ),
            }}
          />
        )}

        {(settings.link || {}).url && (
          <UnderlinedLink
            href={settings.link.url}
            color="black"
            underlineColor={colors.secondary}
          >
            {link_text || 'Learn More'}
          </UnderlinedLink>
        )}
      </div>
    </Intro>
  )
}

export default RaceIntro
