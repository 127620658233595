import React, { useContext, useEffect, useRef } from 'react'
import SiteContext from '../../state/context'
import { ContactFormContainer, FormContainer } from './ContactForm'

const DefaultForm = (props) => {
  const context = useContext(SiteContext)
  const { block, primary, linkBlockBackground } = props
  const { form } = block

  const formRef = useRef(null)

  useEffect(() => {
    const formSubmit = (event) => {
      event.preventDefault()

      const form = formRef.current.querySelector('form')
      const data = new FormData(form)

      const req = new XMLHttpRequest()
      req.onreadystatechange = (event) => {
        if (event.target.response) {
          const response = JSON.parse(event.target.response)
          formRef.current.innerHTML = response.payload
        }
      }
      req.open('POST', form.getAttribute('action'))
      req.send(data)
    }

    if (formRef.current) {
      const form = formRef.current.querySelector('form')

      if (form) {
        form.setAttribute(
          'action',
          `${context.root}${form.getAttribute('action')}`
        )
        form.addEventListener('submit', formSubmit)
      }
    }

    return () => {
      formRef.current
        .querySelector('form')
        .removeEventListener('submit', formSubmit)
    }
  }, [formRef])

  return (
    <ContactFormContainer
      style={{
        display: 'block',
      }}
    >
      <FormContainer
        primary={linkBlockBackground || primary}
        style={{
          maxWidth: 745,
          margin: '0 auto',
        }}
      >
        {form && (
          <div
            ref={formRef}
            dangerouslySetInnerHTML={{
              __html: form,
            }}
          />
        )}
      </FormContainer>
    </ContactFormContainer>
  )
}

export default DefaultForm
