import { createGlobalStyle } from 'styled-components'

const DefaultStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: soleil, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.2;
    font-size: 16px;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  main > a[class*='ButtonLink'] {
    margin: 0 21px 28px !important;

    @media screen and (min-width: 500px) {
      margin: 0 8.3% 36px !important;
    }
  }

  main > div[class*='Wysiwyg'] {
    padding: 0 21px;

    @media screen and (min-width: 500px) {
      padding: 0 8.3%;
    }

    @media screen and (min-width: 768px) {
      width: 66%;
    }
  }

  h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
  }

  h1 {
    font-size: 36px;
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 24px;

    @media screen and (min-width: 768px) {
      font-size: 56px;
      font-size: 3.5rem;
    }

    @media screen and (min-width: 960px) {
      font-size: 76px;
      font-size: 4.75rem;
      margin-bottom: 36px;
    }
  }

  h2 {
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 800;
    margin-bottom: 24px;

    @media screen and (min-width: 500px) {
      font-size: 42px;
      font-size: 2.625rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 62px;
      font-size: 3.875rem;
    }

    @media screen and (min-width: 960px) {
      margin-bottom: 36px;
    }
  }

  h3 {
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 800;
    margin-bottom: 20px;

    @media screen and (min-width: 500px) {
      font-size: 34px;
      font-size: 2.125rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 38px;
      font-size: 2.375rem;
    }

    + h5 {
      margin-top: 32px;
    }
  }

  h4 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 800;
    margin-bottom: 20px;

    @media screen and (min-width: 500px) {
      font-size: 24px;
      font-size: 1.5rem;
    }

    @media screen and (min-width: 960px) {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }

  h5 {
    font-size: 12px;
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media screen and (min-width: 450px) {
      font-size: 16px;
      font-size: 1rem;
    }

    @media screen and (min-width: 600px) {
      font-size: 20px;
      font-size: 1.25rem;
    }
  }

  h6 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 800;
  }

  p {
    font-size: 16px;
    font-size: 1rem;

    @media screen and (min-width: 500px) {
      font-size: 18px;
      font-size: 1.125rem;
    }

    &.large {
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 600;

      @media screen and (min-width: 500px) {
        font-size: 22px;
        font-size: 1.375rem;
      }
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 32px;
    }
  }

  img {
    max-width: 100%;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 724px;
    margin: 0 auto;

    .form-group {
      margin-bottom: 24px;
      width: 100%;

      &--half {
        @media screen and (min-width: 768px) {
          display: inline-block;
          width: 48%;
          margin-right: 4%;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &--thirds {
        @media screen and (min-width: 768px) {
          display: inline-block;
          width: 30%;
          margin-right: 5%;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    span {
      font-size: 16px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      display: block;
      margin-bottom: 5px;
      color: #9b9b9b;
      text-transform: uppercase;

      &.optional {
        margin-left: 10px;
        font-size: 12px;
        font-weight: 400;
      }

      span {
        display: inline;
      }
    }

    input,
    select,
    textarea {
      width: 100%;
      appearance: none;
      height: 48px;
      background: #f7f7f7;
      border-radius: 4px;
      border: 0;
      font-family: inherit;
      font-size: 16px;
      font-size: 1rem;
      padding: 0 16px;
    }

    textarea {
      padding: 16px;
      height: 144px;
    }

    .dropdown-list {
      &__dropdown {
        position: relative;

        &::after {
          content: '';
          border-top: 14px solid #9b9b9b;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          position: absolute;
          bottom: 16px;
          right: 13px;
          pointer-events: none;
        }
      }
    }

    .full-name {
      width: 100%;
    }

    .address-fields {
      > label span {
        color: #9b9b9b;
      }
    }

    .checkboxes {
      > label span {
        color: #555;
      }

      .options {
        margin-top: 12px;
      }

      input {
        position: absolute;
        left: -99999px;
      }

      .checkbox {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        font-size: 18px;
        font-size: 1.125rem;

        span {
          color: #9b9b9b;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-color: #f7f7f7;
          }

          &::after {
            content: '';
            position: absolute;
          }
        }

        input:focus + span::before {
          border-color: #9b9b9b;
        }

        input:checked + span::after {
          content: '';
          width: 9px;
          height: 16px;
          border-bottom: 4px solid #9b9b9b;
          border-right: 4px solid #9b9b9b;
          position: absolute;
          top: 0;
          left: 6px;
          transform: rotate(41deg);
        }
      }
    }

    .radio-buttons {
      > label span {
        color: #555;
      }

      .options {
        margin-top: 12px;
      }

      input {
        position: absolute;
        left: -99999px;
      }

      .radio {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        font-size: 18px;
        font-size: 1.125rem;

        span {
          color: #9b9b9b;

          &::before {
            content: '';
            background-color: #f7f7f7;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
          }

          &::after {
            content: '';
            position: absolute;
          }
        }

        span::before {
          border-radius: 50%;
        }

        input:checked + span::after {
          width: 12px;
          height: 12px;
          background-color: #9b9b9b;
          border-radius: 50%;
          top: 4px;
          left: 4px;
        }

        input:focus + span::before {
          border-color: #9b9b9b;
        }
      }
    }

    .likert-scale {
      .options {
        margin-top: 12px;
        position: relative;
        padding: 18px 0 2px;

        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: space-around;
          padding: 0;
        }

        &::before {
          content: '';
          width: 4px;
          height: 100%;
          background-color: #d8d8d8;
          position: absolute;
          top: 0;
          left: 8px;

          @media screen and (min-width: 768px) {
            width: 100%;
            height: 4px;
            top: 8px;
            left: 0;
          }
        }
      }

      input {
        position: absolute;
        left: -99999px;
      }

      .radio {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 16px;
        font-size: 18px;
        font-size: 1.125rem;
        text-align: left;

        @media screen and (min-width: 768px) {
          text-align: center;
          padding: 28px 6px 0;
        }

        span {
          color: #9b9b9b;

          &::before {
            content: '';
            background-color: #f7f7f7;
            position: absolute;
            top: 2px;
            left: 0;
            width: 20px;
            height: 20px;
            border: 2px solid #dbdbdb;

            @media screen and (min-width: 768px) {
              top: 0;
              left: calc(50% - 10px);
            }
          }

          &::after {
            content: '';
            position: absolute;
          }
        }

        span::before {
          border-radius: 50%;
        }

        input:checked + span::after {
          width: 12px;
          height: 12px;
          background-color: #9b9b9b;
          border-radius: 50%;
          top: 6px;
          left: 4px;

          @media screen and (min-width: 768px) {
            top: 4px;
            left: calc(50% - 6px);
          }
        }

        input:focus + span::before {
          border-color: #9b9b9b;
        }
      }
    }

    .email-field, .phone-field {
      @media screen and (min-width: 768px) {
        max-width: 48%;
        flex: 1 0 auto;
      }
    }

    button {
      border: 0;
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 800;
      font-family: inherit;
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 50px 12px;
      border-radius: 4px;
    }
  }
`

export default DefaultStyles
