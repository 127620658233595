import React, { useEffect, useState } from 'react'

const DateSpan = (props) => {
  const { start, end } = props
  const [dateString, setDateString] = useState('')

  useEffect(() => {
    if (start) {
      getDate()
    }
  }, [start])

  const getDate = () => {
    if (!end) {
      setDateString(start.toFormat('LLLL d, yyyy'))
    } else {
      const startMonth = start.toFormat('LLLL')
      const endMonth = end.toFormat('LLLL')
      if (startMonth !== endMonth) {
        let date = start.toFormat('LLLL d')
        date =
          start.toFormat('yyyy') !== end.toFormat('yyyy')
            ? `${date}, ${start.toFormat('yyyy')} - ${end.toFormat(
                'LLLL d, yyyy'
              )}`
            : `${date} - ${end.toFormat('LLLL d, yyyy')}`
        setDateString(date)
      } else {
        let date = start.toFormat('LLLL')
        date =
          start.toFormat('d') !== end.toFormat('d')
            ? `${date} ${start.toFormat('d')}-${end.toFormat(
                'd'
              )}, ${start.toFormat('yyyy')}`
            : `${date} ${start.toFormat('d')}, ${start.toFormat('yyyy')}`
        setDateString(date)
      }
    }
  }

  return <h1>{dateString}</h1>
}

export default DateSpan
