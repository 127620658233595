import React, { useContext } from 'react'
import styled from 'styled-components'
import { blue } from '../../colors'
import SiteContext from '../../state/context'

const RaceCollageContainer = styled.section`
  & .race-collage-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
      'lg-image-1 lg-image-1'
      'lg-image-1 lg-image-1'
      'image-cutout-1 image-cutout-2'
      'bk-image bk-image'
      'image-cutout-3 sm-image-1'
      'sm-image-2 image-cutout-4';

    & .race-collage-bk-image {
      grid-area: bk-image;
    }

    & .race-collage-sm-image-1 {
      grid-area: sm-image-1;
      grid-row: 4/5;
    }

    & .race-collage-sm-image-2 {
      grid-area: sm-image-2;
      grid-row: 5/6;
    }

    & .race-collage-lg-image-1 {
      grid-area: lg-image-1;
    }

    & .race-collage-image-cutout-1 {
      grid-area: image-cutout-1;
    }

    & .race-collage-image-cutout-2 {
      grid-area: image-cutout-2;
    }

    & .race-collage-image-cutout-3 {
      grid-area: image-cutout-3;
    }

    & .race-collage-image-cutout-4 {
      grid-area: image-cutout-4;
    }

    & .race-collage-bk-image {
      position: absolute;
      bottom: 15%;
      left: 23%;
      width: 157%;
      z-index: -1;
    }

    & .race-collage-sm-image-1,
    .race-collage-sm-image-2,
    .race-collage-lg-image-1 {
      height: 100%;

      & > img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    & .race-collage-image-cutout-1 {
      background-color: ${blue};
      display: grid;
      align-content: center;
      justify-items: center;
      position: relative;

      & > img {
        height: 64px;
        width: 61px;
        object-fit: cover;
        position: relative;
        top: -12px;
      }

      & > h5 {
        color: #000;
        font-size: 10px;
        font-size: 0.625rem;
        margin-bottom: 0px;
        align-self: center;
        position: absolute;
        text-transform: none;
        top: 50%;
        z-index: 1;

        @media screen and (min-width: 500px) {
          font-size: 16px;
          font-size: 1rem;
        }
      }
    }

    & .race-collage-image-cutout-2 {
      position: relative;

      & > img {
        position: absolute;
        bottom: 32%;
        left: 18%;
        transform: rotate(-13deg);
      }
    }

    & .race-collage-small-image-1 {
      grid-column: 2/3;
    }

    & .race-collage-image-cutout-3 {
      position: absolute;
      bottom: 255%;
      left: -22%;
      transform: rotate(-1deg) scaleX(-1);
      width: 58%;
      z-index: -1;
    }

    & .race-collage-image-cutout-4 {
      position: relative;
      width: 106%;

      & > img {
        bottom: 121%;
        left: -37%;
        position: absolute;
        transform: rotate(-10deg);
        z-index: -1;
      }
    }
  }
`

const RightColumnRaceCollage = (props) => {
  const context = useContext(SiteContext)
  const { block } = props
  const {
    bk_image,
    sm_image_1,
    sm_image_2,
    lg_image_1,
    image_cutout_1,
    image_cutout_2,
    image_cutout_3,
    image_cutout_4,
  } = block.settings

  return (
    <RaceCollageContainer>
      <div className="race-collage-container">
        <div className="race-collage-bk-image">
          {(bk_image || {}).image && (
            <img src={`${context.root}${bk_image.image}`} />
          )}
        </div>

        <div className="race-collage-sm-image-1">
          {(sm_image_1 || {}).image && (
            <img src={`${context.root}${sm_image_1.image}`} />
          )}
        </div>

        <div className="race-collage-sm-image-2">
          {(sm_image_2 || {}).image && (
            <img src={`${context.root}${sm_image_2.image}`} />
          )}
        </div>

        <div className="race-collage-lg-image-1">
          {(lg_image_1 || {}).image && (
            <img src={`${context.root}${lg_image_1.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-1">
          {(image_cutout_1 || {}).image && (
            <img src={`${context.root}${image_cutout_1.image}`} />
          )}
          <h5>#MOVEPittsburgh</h5>
        </div>

        <div className="race-collage-image-cutout-2">
          {(image_cutout_2 || {}).image && (
            <img src={`${context.root}${image_cutout_2.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-3">
          {(image_cutout_3 || {}).image && (
            <img src={`${context.root}${image_cutout_3.image}`} />
          )}
        </div>

        <div className="race-collage-image-cutout-4">
          {(image_cutout_4 || {}).image && (
            <img src={`${context.root}${image_cutout_4.image}`} />
          )}
        </div>
      </div>
    </RaceCollageContainer>
  )
}

export default RightColumnRaceCollage
