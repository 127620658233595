import React from 'react'
import ContentHero from './ContentHero'

const MarathonHero = (props) => {
  const { block, background } = props

  return (
    <React.Fragment>
      <ContentHero
        block={block}
        background={background}
        color="black"
        marathon={true}
      />
    </React.Fragment>
  )
}

export default MarathonHero
