import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as Indicator } from '../../images/schedule_day_indicator.svg'

const ScheduleContainer = styled.section`
  margin-top: 56px;
`

const Days = styled.div`
  display: flex;
  padding: 0 6.56% 16px;
  background-color: white;
  position: sticky;
  top: 0;
  ${({ border }) => `
    border-bottom: 4px solid ${border};

    svg path:first-child {
      fill: ${border};
    }
  `}

  @media screen and (min-width: 768px) {
    padding: 0 8.3% 16px;
  }

  button {
    appearance: none;
    border: 0;
    padding: 0;
    font-family: inherit;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 800;
    margin-right: 20px;
    transition: color 0.21s ease-out;
    cursor: pointer;

    @media screen and (min-width: 450px) {
      font-size: 26px;
      font-size: 1.625rem;
    }

    @media screen and (min-width: 768px) {
      font-size: 39px;
      margin-right: 85px;
    }

    @media screen and (min-width: 1080px) {
      font-size: 52px;
      font-size: 3.25rem;
      margin-right: 170px;
    }

    ${({ border }) => `
      &.active {
        color: ${border}
      }

      &:hover,
      &:focus {
        color: ${border};
        outline: none;
      }
    `}

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    width: 56px;
    position: absolute;
    left: 0;
    top: 100%;
    visibility: hidden;
    transform: translateX(-50%);
    transition: left 0.3s ease-out;
  }
`

const EventsContainer = styled.div`
  padding: 65px 8.3% 28px;

  @media screen and (min-width: 768px) {
    padding-top: 77px;
  }

  ${({ border }) => `
    border-bottom: 4px solid ${border};
  `}
`

const Events = styled.div`
  display: none;

  &.active {
    display: block;

    @media screen and (min-width: 768px) {
      display: grid;
      row-gap: 40px;
    }
  }
`

const Event = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    display: grid;
    align-items: center;
    column-gap: 45px;
    grid-template-columns: 30% 70%;
  }

  h3 {
    font-size: 36px;
    font-size: 2.25rem;

    @media screen and (min-width: 768px) {
      font-size: 44px;
      font-size: 2.75rem;
      margin-bottom: 0px;
    }

    @media screen and (min-width: 1080px) {
      font-size: 52px;
      font-size: 3.25rem;
    }
  }

  p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      font-size: 22px;
      font-size: 1.375rem;
      margin-bottom: 0px;
    }
  }
`

const Schedule = (props) => {
  const [activeDay, setActiveDay] = useState(0)
  const { block, border } = props
  const { days } = block.settings

  useEffect(() => {
    positionIndicator()
    window.addEventListener('resize', positionIndicator)

    return () => {
      window.removeEventListener('resize', positionIndicator)
    }
  }, [])

  useEffect(() => {
    positionIndicator()
  }, [activeDay])

  const positionIndicator = () => {
    const daysParent = document.querySelectorAll('.days')

    Array.from(daysParent).forEach((element) => {
      const activeDay = element.querySelector('.active')

      if (activeDay) {
        const left = activeDay.getBoundingClientRect().left
        const middle = left + activeDay.offsetWidth / 2

        const indicator = element.getElementsByTagName('svg')[0]
        if (indicator) {
          indicator.style.visibility = 'visible'
          indicator.style.left = middle
        }
      }
    })
  }

  return (
    <ScheduleContainer>
      <Days border={border} className="days">
        {days.map((day, index) => (
          <button
            key={index}
            type="button"
            className={index === activeDay ? 'active' : ''}
            onClick={() => setActiveDay(index)}
            index={index}
          >
            {day.day}
          </button>
        ))}

        <Indicator />
      </Days>

      <EventsContainer border={border}>
        {days.map((day, index) => (
          <Events key={index} className={index === activeDay ? 'active' : ''}>
            {day.events.map((event, index) => (
              <Event key={index}>
                <h3>{event.time}</h3>
                <p>{event.label}</p>
              </Event>
            ))}
          </Events>
        ))}
      </EventsContainer>
    </ScheduleContainer>
  )
}

export default Schedule
