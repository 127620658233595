import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import Swiper from 'react-id-swiper'

const PartnersContainer = styled.section`
  padding: 32px 21px 48px;

  @media screen and (min-width: 500px) {
    padding: 32px 8.3% 48px;
  }

  @media screen and (min-width: 768px) {
    padding: 57px 8.3% 130px;
  }
`

const PartnersList = styled.div`
  padding: 38px 0;
  margin-top: 24px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  justify-content: space-around;
  align-items: center;

  @media screen and (min-width: 600px) {
    margin-top: 42px;
  }

  @media screen and (min-width: 768px) {
    padding: 46px 90px 26px;
  }

  .swiper-wrapper {
    @media screen and (min-width: 768px) {
      width: auto !important;
      transform: none !important;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;

      & > div {
        width: calc(33.332% - 40px) !important;
        flex: 0 0 auto;
      }
    }
  }
`

const PartnerSlide = styled.div`
  width: 65%;
  height: 250px;

  & div {
    width: 100%;
    height: 100%;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 8px;

    @media screen and (min-width: 768px) {
      padding: 0;
    }
  }

  & a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    max-width: 100%;
    max-height: 100%;
  }
`

const Partners = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block

  const params = {
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 32,
  }

  return (
    <React.Fragment>
      {(settings.logos || []).length > 0 && (
        <PartnersContainer>
          <h2>Partners</h2>

          <PartnersList>
            <Swiper {...params}>
              {settings.logos.map((logo, index) => (
                <PartnerSlide key={index}>
                  <div>
                    {logo.icon.data &&
                      (logo.link.data ? (
                        <a
                          href={logo.link.data.url}
                          target={logo.icon.data.new_tab ? '_blank' : ''}
                        >
                          <img
                            src={`${context.root}${logo.icon.data.image}`}
                            alt={logo.icon.data.description}
                          />
                        </a>
                      ) : (
                        <img
                          src={`${context.root}${logo.icon.data.image}`}
                          alt={logo.icon.data.description}
                        />
                      ))}
                  </div>
                </PartnerSlide>
              ))}
            </Swiper>
          </PartnersList>
        </PartnersContainer>
      )}
    </React.Fragment>
  )
}

export { PartnersContainer, PartnersList, PartnerSlide }

export default Partners
