import React, { useContext, useEffect } from 'react'
import queryString from 'query-string'
import SiteContext from '../../../state/context'
import Block from '../../Block'

const Homepage = (props) => {
  const context = useContext(SiteContext)
  const { site } = props
  const { newsEventsBackgroundFallback } = props
  const {
    primary,
    secondary,
    tertiary,
    darkText,
    countdownBackground,
    menuBackground,
    primaryText,
    cardTitle,
    cardsBackground,
    cardBackground,
    promoBannersBackground,
    link,
    linkUnderline,
    racesBackground,
    racesCardBackground,
    racePromoBackground,
    twoColumnBackground,
    button,
    newsEvents,
  } = props.colors

  useEffect(() => {
    const preview = queryString.parse(location.search).preview

    context.getHomepage(site || 'pittsburgh-marathon', 'race_home', preview)
  }, [])

  return (
    <main>
      {Object.keys(context.page).length > 0 && (
        <React.Fragment>
          {(context.page.blocks || {})['Hero'] &&
            (context.page.blocks || {})['Hero'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  countdownBackground,
                  button,
                  darkText,
                }}
              />
            ))}
          {(context.page.blocks || {})['Content'] &&
            (context.page.blocks || {})['Content'].map((block) => (
              <Block
                key={block.id}
                data={block}
                newsEventsBackgroundFallback={newsEventsBackgroundFallback}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  darkText,
                  menuBackground,
                  primaryText,
                  cardsBackground,
                  cardBackground,
                  cardTitle,
                  promoBannersBackground,
                  link,
                  linkUnderline,
                  racesBackground,
                  racesCardBackground,
                  racePromoBackground,
                  twoColumnBackground,
                  button,
                  newsEvents,
                }}
              />
            ))}
        </React.Fragment>
      )}
    </main>
  )
}

export default Homepage
