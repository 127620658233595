import React, { useContext } from 'react'
import styled from 'styled-components'
import UnderlinedLink from '../UnderlinedLink'
import SiteContext from '../../state/context'
import { hexToRgba } from '../../util'

const CardsContainer = styled.section`
  width: 100%;
  padding: 40px 21px 52px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  ${(props) => `
    ${
      (props.background || {}).image
        ? `
      background: url('${props.root}${
            props.background.image
          }') center no-repeat;
      background-size: cover;
      background-color: ${props.backgroundColor || 'white'};

      > div {
        background-color: white;
      }
    `
        : ''
    }
  `}

  @media screen and (min-width: 500px) {
    padding: 58px 8.3% 68px;
    grid-gap: 38px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 960px) {
    grid-gap: 80px;
  }

  &.three-column {
    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 38px;
    }
  }
`

const Card = styled.div`
  padding: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  @media screen and (min-width: 500px) {
    padding: 30px 30px 34px;
  }

  ${(props) => `
    background-color: ${hexToRgba(props.background, 0.5)};
  `}

  h3 {
    line-height: 1;
    display: flex;
    align-items: center;

    ${(props) => `
      color: ${props.cardTitleColor};
    `}

    @media screen and (min-width: 768px) {
      ${(props) => `
        ${
          !props.shortHeading
            ? `
          min-height: 87px;
        `
            : ''
        }
      `}
    }
  }

  p {
    @media screen and (min-width: 500px) {
      margin-bottom: 36px;
    }
  }

  a {
    margin-top: auto;
  }
`

const CardImage = styled.div`
  width: calc(100% + 60px);
  margin: -21px -21px 21px;
  position: relative;

  @media screen and (min-width: 500px) {
    margin: -30px -30px 22px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 83%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const CardsBackground = (props) => {
  const context = useContext(SiteContext)
  const { block, colors } = props
  const { background, cards } = block.settings

  return (
    <CardsContainer
      className={cards.length > 2 ? 'three-column' : ''}
      root={context.root}
      background={background}
      backgroundColor={colors.cardsBackground || 'white'}
    >
      {cards.map((card, index) => (
        <Card
          key={index}
          background={colors.cardsBackground || colors.primary}
          shortHeading={(background || {}).image}
          cardTitleColor={
            (background || {}).image && colors.cardTitle
              ? colors.cardTitle
              : 'black'
          }
        >
          {(card.image || {}).data && (
            <CardImage>
              <img
                src={`${context.root}${card.image.data.image}`}
                alt={card.image.alt_desc}
              />
            </CardImage>
          )}
          {card.title && <h3>{card.title}</h3>}
          {card.content && <p>{card.content}</p>}

          {(card.link || {}).data && (
            <UnderlinedLink
              href={card.link.data.url}
              color={
                (background || {}).image
                  ? colors.link || colors.tertiary
                  : 'black'
              }
              underlineColor={
                (background || {}).image ? colors.secondary : 'black'
              }
            >
              {card.link.custom_text || 'Learn More'}
            </UnderlinedLink>
          )}
        </Card>
      ))}
    </CardsContainer>
  )
}

export default CardsBackground
