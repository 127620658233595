import React, { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import styled from 'styled-components'

const CountdownWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 2;
`

const Digit = styled.p`
  font-size: 8px;
  font-size: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-right: 8px;
  position: relative;

  @media screen and (min-width: 600px) {
    margin-right: 14px;
    font-size: 12px;
    font-size: 0.75rem;
  }

  @media screen and (min-width: 960px) {
    font-size: 14px;
    font-size: 0.875rem;
  }

  & span:first-of-type {
    display: block;
    width: 32px;
    height: 35px;
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: -0.02em;
    margin-bottom: 3px;

    @media screen and (min-width: 600px) {
      width: 54px;
      height: 60px;
      font-size: 42px;
      font-size: 2.625rem;
      line-height: 1.3;
    }

    @media screen and (min-width: 960px) {
      width: 82px;
      height: 90px;
      font-size: 64px;
      font-size: 4rem;
    }

    ${(props) => `
      background-color: ${props.color};
    `}
  }

  & span:last-of-type {
    position: absolute;
    top: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 600px) {
      position: static;
      transform: none;
    }
  }
`

const Countdown = (props) => {
  const { start } = props
  const [digits, setDigits] = useState({
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const { color } = props

  useEffect(() => {
    getDigits()

    const timer = setInterval(() => {
      getDigits()
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const getDigits = () => {
    const now = DateTime.local()
    const difference = start.diff(now, [
      'months',
      'days',
      'hours',
      'minutes',
      'seconds',
    ])

    const { months, days, hours, minutes, seconds } = difference.values
    setDigits({
      months,
      days,
      hours,
      minutes,
      seconds: parseInt(seconds),
    })
  }

  return (
    <CountdownWrapper>
      <Digit color={color}>
        <span>{digits.months}</span>
        <span>Months</span>
      </Digit>

      <Digit color={color}>
        <span>{digits.days}</span>
        <span>Days</span>
      </Digit>

      <Digit color={color}>
        <span>{digits.hours}</span>
        <span>Hours</span>
      </Digit>

      <Digit color={color}>
        <span>{digits.minutes}</span>
        <span>Minutes</span>
      </Digit>

      <Digit color={color}>
        <span>{digits.seconds}</span>
        <span>Seconds</span>
      </Digit>
    </CountdownWrapper>
  )
}

export default Countdown
