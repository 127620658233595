import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import UnderlinedLink from '../UnderlinedLink'

const PromoContainer = styled.section`
  position: relative;

  ${(props) => `
    background: ${props.background};
    color: ${props.linkColor};
  `}

  margin-top: 26px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12%;
  padding: 30px 8.3% 90px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16%;
    padding-top: 48px;
    padding-bottom: 132px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 26px;
    position: absolute;
    bottom: 100%;
    left: 0;
    ${(props) => `
      ${
        (props.border || {}).image
          ? `
        background: url('${props.root}${props.border.image}') bottom center repeat-x;
      `
          : ''
      }
    `}
  }

  * {
    color: inherit;
  }
`

const Promo = styled.div`
  & h2 {
    margin-bottom: 14px;

    @media screen and (max-width: 499px) {
      font-size: 34px;
      font-size: 2.125rem;
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 0px;
    }
  }

  & > a {
    margin-top: 18px;
  }

  & div {
    & p {
      font-size: 18px;
      font-size: 1.125rem;
      line-height: 1.38;
      font-weight: 700;
      margin-bottom: 0px;

      @media screen and (min-width: 768px) {
        font-size: 26px;
        font-size: 1.625rem;
        margin-bottom: 18px;
      }
    }
  }
`

const TwoColumnPromo = (props) => {
  const context = useContext(SiteContext)
  const { block } = props
  const { colors } = props
  const {
    border_image,
    heading_1,
    heading_2,
    copy_1,
    copy_2,
    link_1,
    link_2,
    link_text_1,
    link_text_2,
  } = block.settings

  return (
    <PromoContainer
      border={border_image}
      root={context.root}
      background={colors.twoColumnBackground || 'black'}
      linkColor={colors.linkColor || 'white'}
    >
      <Promo>
        {heading_1 && <h2>{heading_1}</h2>}

        {copy_1 && (
          <div
            dangerouslySetInnerHTML={{
              __html: copy_1.replace(/(\/media)+/g, `${context.root}/media`),
            }}
          />
        )}

        {(link_1 || {}).url && (
          <UnderlinedLink href={link_1.url} color={colors.linkColor}>
            {link_text_1 || 'Learn More'}
          </UnderlinedLink>
        )}
      </Promo>

      <Promo>
        {heading_2 && <h2>{heading_2}</h2>}

        {copy_2 && (
          <div
            dangerouslySetInnerHTML={{
              __html: copy_2.replace(/(\/media)+/g, `${context.root}/media`),
            }}
          />
        )}

        {(link_2 || {}).url && (
          <UnderlinedLink href={link_2.url} color={colors.linkColor}>
            {link_text_2 || 'Learn More'}
          </UnderlinedLink>
        )}
      </Promo>
    </PromoContainer>
  )
}

export default TwoColumnPromo
