import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { Accordion, TopQuestionsAccordions } from './TopQuestions'
import Collapse from '@material-ui/core/Collapse'
import { ReactComponent as Plus } from '../../images/icon_plus.svg'
import dropArrow from '../../images/arrow_show.svg'
import { hexToRgba } from '../../util'
import UnderlinedLink from '../UnderlinedLink'
import ConditionalWrap from 'conditional-wrap'

const AccordionsContainer = styled.section`
  margin-top: 20px;
  margin-bottom: 72px;

  > h2 {
    padding-left: 8.3%;
    margin-bottom: 32px;

    @media screen and (min-width: 1080px) {
      margin-bottom: 67px;
    }
  }

  + section[class*='AccordionsContainer'] {
    margin-top: -54px;
  }
`

const AccordionsInner = styled.div`
  padding: 0 8.3vw;

  @media screen and (min-width: 1080px) {
    display: flex;
    padding-right: 0;
  }

  .sidebar {
    @media screen and (min-width: 1080px) {
      width: 55.5%;
    }

    svg {
      right: 0;
    }
  }
`

const AccordionsGroup = styled.div`
  @media screen and (min-width: 1080px) {
    margin-right: 158px;
    width: 55.5%;
  }

  .default-accordion {
    color: black;
    margin-bottom: 14px;

    button {
      color: black;
      border-radius: 8px;
      padding: 10px 50px 12px 18px;
      font-size: 16px;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 700;
      ${(props) => `
        background-color: ${hexToRgba(props.accordionBackground, 0.5)};
      `}

      svg {
        right: 30px;

        path {
          fill: black;
        }
      }
    }

    > div {
      padding: 0 18px;

      a {
        ${({ links }) => `
          color: ${links};
          transition: opacity 0.21s ease-out;

          &:hover,
          &:focus {
            opacity: 0.65;
          }
        `}
      }
    }
  }
`

const AccordionSubheading = styled.button`
  display: block;
  width: 100%;
  text-align: left;
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
  border-radius: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    h3 {
      opacity: 0.7;
    }
  }

  h3 {
    position: relative;
    transition: opacity 0.21s ease-out;

    &::after {
      content: '';
      width: 15px;
      height: 23px;
      background: url(${dropArrow}) center no-repeat;
      background-size: contain;
      position: absolute;
      top: calc(50% + 2px);
      right: 31px;
      transform: translateY(-50%) rotate(-90deg);
      transition: transform 0.21s ease-out;

      ${({ hide }) =>
        hide &&
        `
        transform: translateY(-50%) rotate(90deg);
      `}

      @media screen and (min-width: 500px) {
        width: 18px;
        height: 37px;
        top: calc(50% + 4px);
        right: 33px;
      }
    }
  }
`

const AccordionsWrap = styled.div`
  padding-bottom: 36px;
`

const Accordions = (props) => {
  const context = useContext(SiteContext)
  const { block, colors } = props
  const {
    accordions,
    callout_copy,
    callout_heading,
    callout_link,
    callout_link_text,
    collapsible_subheading,
    heading,
    top_questions,
    use_copy_block_instead_of_top_questions,
  } = block.settings

  const [accordionsList, setAccordionsList] = useState(accordions)
  const [topQuestions, setTopQuestions] = useState(top_questions)
  const [showAccordions, setShowAccordions] = useState(true)

  return (
    <AccordionsContainer>
      {heading && <h2>{heading}</h2>}

      <AccordionsInner>
        <AccordionsGroup
          accordionBackground={colors.accordionBackground || colors.primary}
          links={colors.tertiary}
        >
          {collapsible_subheading && (
            <AccordionSubheading
              onClick={() => setShowAccordions(!showAccordions)}
              hide={!showAccordions}
            >
              <h3>{collapsible_subheading}</h3>
            </AccordionSubheading>
          )}

          <ConditionalWrap
            condition={collapsible_subheading}
            wrap={(children) => (
              <Collapse in={showAccordions}>{children}</Collapse>
            )}
          >
            <AccordionsWrap>
              {(accordionsList || []).map((accordion, index) => (
                <Accordion key={index} className="default-accordion">
                  <button
                    type="button"
                    onClick={() => {
                      const accordionClone = [...accordionsList]
                      accordionClone[index].open = !accordionClone[index].open

                      setAccordionsList(accordionClone)
                    }}
                  >
                    {accordion.title}

                    <Plus className={accordion.open ? 'open' : ''} />
                  </button>

                  <Collapse in={accordion.open}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: accordion.content.replace(
                          /(\/media)+/g,
                          `${context.root}/media`
                        ),
                      }}
                    />
                  </Collapse>
                </Accordion>
              ))}
            </AccordionsWrap>
          </ConditionalWrap>
        </AccordionsGroup>

        {use_copy_block_instead_of_top_questions
          ? (callout_copy || callout_heading || callout_link) && (
              <TopQuestionsAccordions
                background={colors.tertiary}
                className="sidebar"
              >
                {callout_heading && <h2>{callout_heading}</h2>}

                {callout_copy && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: callout_copy.replace(
                        /(\/media)+/g,
                        `${context.root}/media`
                      ),
                    }}
                  />
                )}

                {callout_link && (
                  <UnderlinedLink href={callout_link.url}>
                    {callout_link_text || 'Learn More'}
                  </UnderlinedLink>
                )}
              </TopQuestionsAccordions>
            )
          : (top_questions || []).length > 0 &&
            top_questions[0].title && (
              <TopQuestionsAccordions
                background={colors.tertiary}
                className="sidebar"
              >
                <h2>Top Questions</h2>

                {topQuestions.map((accordion, index) => (
                  <Accordion key={index}>
                    <button
                      type="button"
                      onClick={() => {
                        const accordionClone = [...topQuestions]
                        accordionClone[index].open = !accordionClone[index].open

                        setTopQuestions(accordionClone)
                      }}
                    >
                      {accordion.title}

                      <Plus className={accordion.open ? 'open' : ''} />
                    </button>

                    <Collapse in={accordion.open}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: accordion.content.replace(
                            /(\/media)+/g,
                            `${context.root}/media`
                          ),
                        }}
                      />
                    </Collapse>
                  </Accordion>
                ))}
              </TopQuestionsAccordions>
            )}
      </AccordionsInner>
    </AccordionsContainer>
  )
}

export default Accordions
