import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import Block from '../../Block'
import Columns, { LeftColumn, RightColumn } from '../../columns'
import SiteContext from '../../../state/context'

const Page = (props) => {
  const { pageId, mapboxToken, site } = props
  const context = useContext(SiteContext)
  const historyParams = useParams()
  const {
    primary,
    secondary,
    tertiary,
    darkText,
    primaryText,
    linkBlockBackground,
    linkColor,
    linkUnderline,
    accordionBackground,
    twoColumnBackground,
    cardsBackground,
    button,
  } = props.colors

  useEffect(() => {
    const preview = queryString.parse(location.search).preview

    context.getPage(
      pageId || historyParams.id,
      site || 'pittsburgh-marathon',
      preview
    )
  }, [])

  return (
    <main>
      {Object.keys(context.page).length > 0 && (
        <React.Fragment>
          {(context.page.blocks || {})['Hero'] &&
            (context.page.blocks || {})['Hero'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  linkColor,
                  button,
                  darkText,
                }}
              />
            ))}
          <Columns>
            <LeftColumn>
              {(context.page.blocks || {})['Left Column'] &&
                (context.page.blocks || {})['Left Column'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      linkBlockBackground,
                      linkColor,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </LeftColumn>

            <RightColumn>
              {context.page.blocks['Right Column'] &&
                context.page.blocks['Right Column'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      linkBlockBackground,
                      linkColor,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </RightColumn>
          </Columns>

          {context.page.blocks['Above Footer'] &&
            context.page.blocks['Above Footer'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  primaryText,
                  linkBlockBackground,
                  linkColor,
                  linkUnderline,
                  accordionBackground,
                  twoColumnBackground,
                  cardsBackground,
                  button,
                  darkText,
                }}
                mapboxToken={mapboxToken}
              />
            ))}
        </React.Fragment>
      )}
    </main>
  )
}

export default Page
