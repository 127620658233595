import React from 'react'
import styled from 'styled-components'
import runner from '../../images/runner-circle2x.png'
import runnerMotion from '../../images/runner-motion.gif'

const MenuContainer = styled.nav`
  padding: 0;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: black;
  overflow: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;

  & .menu-track {
    padding: 16px 16px 12px;
    overflow-y: hidden;
    white-space: nowrap;
    align-items: center;
    display: flex;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    @media screen and (min-width: 600px) {
      padding: 32px 32px 24px;
    }
  }

  & img {
    max-width: 20px;
    height: 100%;
    margin: -5px 14px 0 0;

    @media screen and (min-width: 600px) {
      max-width: 41px;
      margin: -8px 18px 0 0;
    }
  }

  .runner-static {
    display: none;
    @media (prefers-reduced-motion) {
      display: block;
    }
  }

  .runner-motion {
    @media (prefers-reduced-motion) {
      display: none;
    }
  }

  & ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    @media screen and (min-width: 600px) {
      justify-content: center;
    }
  }

  & li {
    margin-bottom: 8px;
  }

  & a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 800;
    padding: 0 18px 0 0;
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    @media screen and (min-width: 600px) {
      font-size: 24px;
      font-size: 1.5rem;
      padding: 0 16px;
    }
  }
`

const Menu = (props) => {
  const { background, block, darkText } = props

  return (
    <MenuContainer background={background} darkText={darkText}>
      <div className="menu-track">
        <img className="runner-static" src={runner} alt="runner emoji" />
        <img className="runner-motion" src={runnerMotion} alt="runner emoji" />
        <ul
          dangerouslySetInnerHTML={{
            __html: block.menu,
          }}
        />
      </div>
    </MenuContainer>
  )
}

export default Menu
