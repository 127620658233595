import React from 'react'
import styled from 'styled-components'
import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from 'recharts'

const CourseChartContainer = styled.div`
  margin: 10px 8.3% 52px;
  background-color: #f7f7f7;

  .recharts-label {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    color: #555;
  }
`

const DistanceTick = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={12}
        textAnchor="middle"
        fill="#BDBDBD"
        fontSize="12px"
      >{`${parseInt(payload.value)} mi`}</text>
    </g>
  )
}

const HeightTick = (props) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-6}
        dy={6}
        textAnchor="end"
        fill="#BDBDBD"
        fontSize="12px"
      >{`${parseInt(payload.value)} ft`}</text>
    </g>
  )
}

const CourseChart = (props) => {
  const { elevations } = props

  return (
    <CourseChartContainer>
      <ResponsiveContainer height={172}>
        <AreaChart
          data={elevations}
          margin={{
            top: 18,
            right: 14,
            left: 36,
            bottom: 36,
          }}
        >
          <YAxis
            dataKey="height"
            label={{
              value: 'Height',
              angle: -90,
              position: 'left',
            }}
            tick={HeightTick}
          />
          <XAxis
            dataKey="distance"
            label={{
              value: 'Distance',
              position: 'bottom',
            }}
            tick={DistanceTick}
            tickLine={false}
          />
          <Area
            type="monotone"
            dataKey="height"
            fill="#56B924"
            stroke="#56B924"
          />
        </AreaChart>
      </ResponsiveContainer>
    </CourseChartContainer>
  )
}

export default CourseChart
