import styled from 'styled-components'

const UnderlinedLink = styled.a`
  display: inline-block;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 4px solid white;
  padding-bottom: 4px;
  margin-top: 16px;
  transition: padding-right 0.21s ease-out;
  ${(props) => `
    ${
      props.color
        ? `
      color: ${props.color};
      border-bottom-color: ${props.underlineColor || props.color};
    `
        : ''
    }
  `}

  @media screen and (min-width: 500px) {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 32px;
  }

  &:hover,
  &:focus {
    padding-right: 20px;
  }
`

export default UnderlinedLink
