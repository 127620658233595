import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import SiteContext from '../../../state/context'
import {
  displayDate,
  Card,
  CardDate,
  CardDetails,
  CardImage,
} from '../../blocks/NewsEvents'
import { Button } from '../../Button'
import ContentHero from '../../blocks/ContentHero'

const FeaturedCard = styled(Card)`
  padding: 28px 0 32px;
  margin: 64px 8.3vw 0;
  display: flex;
  flex-direction: column-reverse;

  ${(props) => `
    color: ${props.primaryText || 'black'};
  `}

  @media screen and (min-width: 960px) {
    flex-direction: row;
    padding: 0;
  }

  figure {
    width: calc(100% - 16px);
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 600px) {
      width: calc(100% - 52px);
    }

    @media screen and (min-width: 960px) {
      width: 56%;
      flex: 0 0 auto;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 100%;
      transform: skew(-30.5deg);
      transform-origin: top left;
      ${(props) => `
        background-color: ${props.background};
      `}

      @media screen and (min-width: 960px) {
        display: none;
      }
    }
  }

  img {
    display: block;
  }

  > div {
    flex: 1 1 auto;
    position: relative;
    padding: 0 21px 32px;

    @media screen and (min-width: 960px) {
      padding: 52px 21px 21px 11px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    * {
      position: relative;
    }

    &::before {
      @media screen and (min-width: 960px) {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: skew(-30.5deg);
        transform-origin: top left;
        ${(props) => `
          background-color: ${props.background};
        `}
      }
    }
  }

  h3 {
    margin-bottom: 16px;
    transition: color 0.21s ease-out;

    + div {
      margin-bottom: 19px;
    }
  }

  &:hover,
  &:focus {
    h3 {
      color: white;
    }
  }
`

const NewsFilters = styled.div`
  padding: 54px 21px 0;

  @media screen and (min-width: 500px) {
    padding: 54px 8.3% 0;
  }

  @media screen and (min-width: 680px) {
    display: flex;
    align-items: center;
  }

  span {
    display: inline-block;
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    margin-bottom: 8px;

    @media screen and (min-width: 680px) {
      margin-right: 18px;
      margin-bottom: 0;
    }
  }

  div {
    position: relative;

    @media screen and (min-width: 680px) {
      width: 393px;
    }

    &::after {
      content: '';
      border-top: 14px solid #9b9b9b;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      bottom: 16px;
      right: 13px;
      pointer-events: none;
    }

    select {
      width: 100%;
      appearance: none;
      height: 48px;
      background: #f7f7f7;
      border-radius: 4px;
      border: 0;
      font-family: inherit;
      font-size: 16px;
      font-size: 1rem;
      padding: 0 16px;
    }
  }
`

const NewsList = styled.section`
  padding: 48px 21px 80px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px;

  @media screen and (min-width: 500px) {
    padding: 66px 8.3vw 80px;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 960px) {
    grid-gap: 64px 98px;
  }

  > a {
    ${(props) => `
      color: ${props.primaryText};
    `}

    h3 {
      transition: color 0.21s ease-out;
      ${(props) => `
        color: ${props.primaryText};
      `}
    }

    &:hover,
    &:focus {
      h3 {
        color: white;
      }
    }
  }
`

const LoadMoreContainer = styled.div`
  text-align: center;
  margin: -16px 0 80px;
`

const News = (props) => {
  const context = useContext(SiteContext)
  const [page, setPage] = useState(1)
  const [categories, setCategories] = useState([])
  const [filters, setFilters] = useState({
    category: '',
  })
  const { colors, type, site } = props
  const { primary, secondary } = colors
  const { newsEventsBackgroundFallback } = props

  useEffect(() => {
    context.getFeaturedNews(site || 'pittsburgh-marathon', type === 'news')

    context.getNews(site || 'pittsburgh-marathon', type === 'news')

    const getCategories = new Promise((resolve) => {
      context.getNewsCategories(resolve)
    })

    getCategories.then((data) => {
      setCategories(data)
    })
  }, [])

  const loadMore = () => {
    const newPage = page + 1

    context.getNews(
      site || 'pittsburgh-marathon',
      type === 'news',
      filters.category,
      newPage
    )

    setPage(newPage)
  }

  const handleFilters = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    })

    context.getNews(
      site || 'pittsburgh-marathon',
      type === 'news',
      event.target.value
    )

    setPage(1)
  }

  return (
    <main>
      <ContentHero
        background={secondary}
        block={{
          settings: {
            heading: type === 'blog' ? 'Blog' : 'News',
          },
        }}
      />

      {context.featuredNews.map((post) => (
        <FeaturedCard
          key={post.id}
          href={post.url}
          background={primary}
          primaryText={colors.primaryText}
        >
          {post.image ? (
            <CardImage>
              <img src={`${context.root}${post.image}`} />
            </CardImage>
          ) : props.newsEventsBackgroundFallback ? (
            <CardImage>
              <img src={props.newsEventsBackgroundFallback} />
            </CardImage>
          ) : null}

          <CardDetails>
            <h3>{post.title}</h3>

            <CardDate>
              <span>{post.published && displayDate(post.published)}</span>

              <span>
                {post.categories.map((category, index) => {
                  if (index > 0) {
                    return `, ${category}`
                  } else {
                    return category
                  }
                })}
              </span>
            </CardDate>

            {post.excerpt && (
              <div
                dangerouslySetInnerHTML={{
                  __html: post.excerpt,
                }}
              />
            )}
          </CardDetails>
        </FeaturedCard>
      ))}

      <NewsFilters>
        <span>filter by:</span>

        <div>
          <select
            name="category"
            value={filters.category}
            onChange={handleFilters}
          >
            <option value="">Category</option>
            {categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </NewsFilters>

      <NewsList primaryText={colors.button}>
        {context.news.map((post) => (
          <Card
            key={post.id}
            href={post.url}
            background={primary}
            primaryText={colors.primaryText}
          >
            {post.image ? (
              <CardImage>
                <img src={`${context.root}${post.image}`} />
              </CardImage>
            ) : props.newsEventsBackgroundFallback ? (
              <CardImage>
                <img src={props.newsEventsBackgroundFallback} />
              </CardImage>
            ) : null}

            <CardDetails>
              <CardDate>
                <span>{post.published && displayDate(post.published)}</span>

                <span>
                  {post.categories.map((category, index) => {
                    if (index > 0) {
                      return `, ${category}`
                    } else {
                      return category
                    }
                  })}
                </span>
              </CardDate>

              <h3>{post.title}</h3>

              {post.excerpt && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt,
                  }}
                />
              )}
            </CardDetails>
          </Card>
        ))}
      </NewsList>

      {context.total > context.news.length && (
        <LoadMoreContainer>
          <Button onClick={loadMore} background="black" color="white">
            More Posts
          </Button>
        </LoadMoreContainer>
      )}
    </main>
  )
}

export default News
