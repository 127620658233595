import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import Swiper from 'react-id-swiper'
import { ReactComponent as ArrowLeft } from '../../images/arrow_left.svg'
import { ReactComponent as ArrowRight } from '../../images/arrow_right.svg'

const GalleryContainer = styled.section`
  padding: 86px 0;
  position: relative;

  .swiper-wrapper,
  .swiper-container {
    position: static;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: none;
    border: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    top: auto;
    bottom: 41px;
    margin-top: 0;
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.75;
      background: none;
    }

    &::after {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;

      * {
        ${({ color }) => `
          stroke: ${color};
        `}
      }
    }
  }

  .swiper-button-next {
    right: 5%;

    @media screen and (min-width: 768px) {
      right: 20%;
    }
  }

  .swiper-button-prev {
    left: auto;
    right: calc(5% + 54px);

    @media screen and (min-width: 768px) {
      right: calc(20% + 54px);
    }
  }
`

const Slide = styled.div`
  width: 90%;

  @media screen and (min-width: 768px) {
    width: 60%;
  }

  > div {
    position: relative;
    margin-bottom: 17px;

    &::before {
      content: '';
      width: 100%;
      display: block;
      padding-top: 56%;
    }
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  p {
    font-size: 13px;
    font-size: 0.8125rem;
    margin: 0;
  }
`

const Prev = () => (
  <button className="swiper-button-prev">
    <ArrowLeft />
  </button>
)

const Next = () => (
  <button className="swiper-button-next">
    <ArrowRight />
  </button>
)

const Gallery = (props) => {
  const context = useContext(SiteContext)
  const { block, color } = props
  const { gallery } = block.settings

  const params = {
    centeredSlides: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 'auto',
    spaceBetween: 9,
    breakpoints: {
      768: {
        spaceBetween: 65,
      },
    },
    renderPrevButton: () => <Prev />,
    renderNextButton: () => <Next />,
  }

  return (
    <React.Fragment>
      {(gallery || {}).slides && (
        <GalleryContainer color={color}>
          <Swiper {...params}>
            {gallery.slides.map(
              (slide, index) =>
                slide.data && (
                  <Slide key={index}>
                    <div>
                      <img src={`${context.root}${slide.data.image}`} />
                    </div>

                    {slide.caption && <p>{slide.caption}</p>}
                  </Slide>
                )
            )}
          </Swiper>
        </GalleryContainer>
      )}
    </React.Fragment>
  )
}

export default Gallery
