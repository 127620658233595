import React from 'react'

export default React.createContext({
  site: {},
  page: {},
  race: {},
  events: [],
  event: {},
  featuredNews: [],
  news: [],
  post: {},
  total: 0,
  results: [],
  root: 'http://localhost:4242',
  getSite: (slug) => {},
  getHomepage: (site, template, preview) => {},
  getPage: (id, site, preview) => {},
  getRace: (id, site) => {},
  getEvents: (site, category, query) => {},
  getEventCategories: (resolve) => {},
  getEvent: (id, site, preview) => {},
  getFeaturedNews: (site, news) => {},
  getNews: (site, news, category, page) => {},
  getNewsCategories: (resolve) => {},
  clearTotal: () => {},
  getPost: (id, site, news, preview) => {},
  getResults: (site, query) => {},
})
