import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { ReactComponent as Play } from '../../images/icon_play.svg'
import Vimeo from '@vimeo/player'

const VideoContainer = styled.section`
  margin: 30px 0;
  padding: 21px;
  ${(props) => `
    background: ${props.background};
    background-size: cover;
  `}

  @media screen and (min-width: 500px) {
    padding: 32px 8.3%;
  }

  @media screen and (min-width: 768px) {
    margin: 50px 0 50px;
    padding: 88px 8.3%;
  }

  @media screen and (min-width: 960px) {
    padding: 88px 94px 142px;
  }
`

const VideoFrame = styled.div`
  position: relative;
`

const VideoCover = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: opacity 0.3s ease-out;

  & img {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`

const PlayButton = styled.button`
  appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 25%;
  height: 25%;
  position: relative;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;
    transition: opacity 0.21s ease-out;
  }

  &:hover,
  &:focus {
    background-color: transparent;

    & svg {
      opacity: 0.75;
    }
  }
`

const VideoFile = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  video,
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const VideoBackground = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block

  useEffect(() => {
    if ((settings || {}).video) {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'

      const currentScripts = document.querySelector('script[src*="youtube"]')
      if (!currentScripts) {
        const firstScript = document.getElementsByTagName('script')[0]
        firstScript.parentNode.insertBefore(tag, firstScript)
      }
    }
  }, [])

  const onPlayerReady = (event) => {
    event.target.playVideo()
  }

  const playVideo = (event) => {
    const button = event.currentTarget
    const cover = button.parentNode

    cover.style.pointerEvents = 'none'
    cover.style.opacity = 0

    const video = cover.nextElementSibling.querySelector('video')
    const iframe = cover.nextElementSibling.querySelector('iframe')

    if (video) {
      video.play()
    } else if (iframe.getAttribute('src').match('youtube')) {
      // eslint-disable-next-line no-new
      new YT.Player(iframe.getAttribute('id'), {
        events: {
          onReady: onPlayerReady,
        },
      })
    } else if (iframe.getAttribute('src').match('vimeo')) {
      const vimeoVideo = new Vimeo(iframe.getAttribute('id'))
      vimeoVideo.play()
    }
  }

  return (
    <React.Fragment>
      {settings.video && (
        <VideoContainer
          background={
            settings.background
              ? `url('${context.root}${settings.background.image}') center no-repeat`
              : ''
          }
        >
          <VideoFrame>
            {settings.cover_image && (
              <VideoCover className="fade">
                <img src={`${context.root}${settings.cover_image.image}`} />
                <PlayButton
                  aria-label="click here to play video"
                  onClick={playVideo}
                >
                  <Play />
                </PlayButton>
              </VideoCover>
            )}

            <VideoFile>
              {settings.video.video_id ? (
                <React.Fragment>
                  {settings.video.video_type === 'youtube' ? (
                    <iframe
                      id={`video-${[...Array(6)]
                        .map((i) => (~~(Math.random() * 36)).toString(36))
                        .join('')}`}
                      src={`//www.youtube.com/embed/${settings.video.video_id}?rel=0&autoplay=0&mute=0&showinfo=0&controls=1&enablejsapi=1`}
                      frameBorder={0}
                      allowFullScreen=""
                    />
                  ) : (
                    <iframe
                      id={`video-${[...Array(6)]
                        .map((i) => (~~(Math.random() * 36)).toString(36))
                        .join('')}`}
                      src={`//player.vimeo.com/video/${settings.video.video_id}?autoplay=0&loop=0&title=0&byline=0&portrait=0`}
                      frameBorder={0}
                      allowFullScreen=""
                    />
                  )}
                </React.Fragment>
              ) : (
                <video video="" controls="">
                  {settings.video && (
                    <source
                      src={`${context.root}${settings.video.image}`}
                      type="video/mp4"
                    />
                  )}

                  {settings.video_webm && (
                    <source
                      src={`${context.root}${settings.video_webm.image}`}
                      type="video/webm"
                    />
                  )}
                </video>
              )}
            </VideoFile>
          </VideoFrame>
        </VideoContainer>
      )}
    </React.Fragment>
  )
}

export default VideoBackground
