import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SiteContext from '../state/context'
import { ReactComponent as FacebookIcon } from '../images/social_facebook.svg'
import { ReactComponent as InstagramIcon } from '../images/social_instagram.svg'
import { ReactComponent as TwitterIcon } from '../images/social_twitter.svg'

const FooterContainer = styled.footer`
  padding: 0px 21px 106px;
  color: white;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${(props) => `
    background-color: ${props.background || 'black'};
  `}

  @media screen and (min-width: 500px) {
    padding: 0px 8.3% 148px;
  }

  @media screen and (min-width: 800px) {
    flex-direction: row;
    padding-bottom: 132px;
  }

  @media screen and (min-width: 1400px) {
    padding-bottom: 240px;
  }

  > svg {
    display: none;

    @media screen and (min-width: 1200px) {
      display: block;
      position: absolute;
      bottom: -11px;
      left: -57px;
    }
  }

  & > div {
    position: relative;

    &:first-of-type {
      width: 50%;
      flex: 0 0 auto;

      @media screen and (min-width: 800px) {
        width: 40%;
      }

      @media screen and (min-width: 1120px) {
        width: 50%;
      }

      @media screen and (min-width: 1440px) {
        width: 45%;
      }

      > img {
        width: 250px;

        @media screen and (min-width: 1200px) {
          width: auto;
        }
      }
    }

    &:last-of-type {
      margin-top: 160px;
      flex: 1 1 auto;

      @media screen and (min-width: 600px) {
        margin-top: 165px;
      }

      @media screen and (min-width: 800px) {
        margin-top: 65px;
      }
    }
  }

  nav.footer-menu {
    > ul {
      column-count: 2;
      column-gap: 34px;

      @media screen and (min-width: 500px) {
        column-gap: 60px;
      }

      @media screen and (min-width: 1440px) {
        column-gap: 55px;
      }
    }
  }

  nav {
    margin-bottom: 28px;

    @media screen and (min-width: 1440px) {
      display: inline-block;
      position: relative;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
      margin-bottom: 26px;
    }
  }

  li {
    break-inside: avoid;
  }

  a {
    display: block;
    text-decoration: none;
    font-size: 14px;
    font-size: 0.875rem;
    color: white;
    font-weight: 400;
    margin-bottom: 2px;
    transition: opacity 0.21s ease-out;

    @media screen and (min-width: 500px) {
      font-size: 18px;
      font-size: 1.125rem;
    }

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }

  nav > ul {
    > li > a {
      font-weight: 800;

      ${(props) => `
        color: ${props.primaryText || '#bdbdbd'};
      `}
    }
  }
`

const FooterLogo = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  top: 0;
  left: 0px;

  @media screen and (min-width: 1120px) {
    top: -40px;
    margin-top: 40px;
  }

  &::before {
    display: block;
    position: absolute;
    content: '';
    background: white;
    width: 145px;
    height: 122px;
    left: 15px;
    transform: skew(-13.5deg);
    box-shadow: 0 25px 48px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 1120px) {
      width: 307px;
      height: 231px;
      left: 28px;
    }
  }

  & img {
    width: 95px;
    height: 91px;
    position: relative;
    z-index: 1;
    left: 40px;
    top: 11px;

    @media screen and (min-width: 1120px) {
      width: 177px;
      height: 172px;
      left: 86px;
      top: 26px;
    }
  }
`

const Social = styled.div`
  margin-top: 38px;
  margin-bottom: 28px;

  @media screen and (min-width: 800px) {
    margin-top: 0;
    margin-left: 0;
    padding-left: calc(50% + 30px);
  }

  @media screen and (min-width: 1440px) {
    display: inline-block;
    position: absolute;
    margin-left: 85px;
    padding-left: 0px;
  }

  nav {
    margin-bottom: 20px;

    @media screen and (min-width: 1440px) {
      display: flex;
      margin-bottom: 0px;
    }

    li {
      margin-bottom: 10px;
    }
  }

  .social-icon {
    width: 38px;
    height: 38px;
    background-color: white;
    border-radius: 50%;
    transition: opacity 0.21s ease-out;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }

  svg {
    width: 20px;
    height: 20px;

    * {
      fill: black;
    }
  }
`

const Credit = styled.section`
  position: absolute;
  bottom: 54px;
  left: 21px;

  @media screen and (min-width: 500px) {
    left: 8.3vw;
    bottom: 88px;
  }

  @media screen and (min-width: 800px) {
    bottom: 92px;
  }

  p,
  a {
    ${(props) => `
      color: ${props.primaryText || '#bdbdbd'};
    `}

    font-size: 10px;
    font-size: 0.625rem;

    @media screen and (min-width: 450px) {
      font-size: 13px;
      font-size: 0.8125rem;
    }
  }

  p {
    a:not(.privacy-policy) {
      display: inline-block;
    }
  }
`

const DefaultFooter = (props) => {
  const { menus, background, primaryText } = props
  const { facebook, instagram, twitter } = props.social
  const context = useContext(SiteContext)

  useEffect(() => {
    if (props.site === 'eqt-pittsburgh-10-miler') {
      context.getSite('eqt-pittsburgh-10-miler')
    } else if (props.site === 'fleet-feet-liberty-mile') {
      context.getSite('fleet-feet-liberty-mile')
    }
  }, [])

  return (
    <FooterContainer background={background} primaryText={primaryText}>
      <div>
        <FooterLogo to="/">
          {context.site && (
            <img
              src={`${context.root}${context.site.logo}`}
              alt={props.logoAlt}
            />
          )}
        </FooterLogo>
      </div>

      {menus && (
        <div>
          {menus.footer && (
            <nav
              className="footer-menu"
              dangerouslySetInnerHTML={{
                __html: menus.footer,
              }}
            />
          )}

          <Social>
            {menus.footer_secondary && (
              <nav
                dangerouslySetInnerHTML={{
                  __html: menus.footer_secondary,
                }}
              />
            )}

            {facebook && (
              <a
                className="social-icon"
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </a>
            )}

            {instagram && (
              <a
                className="social-icon"
                href={instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </a>
            )}

            {/* <a
              className='social-icon'
              href='/404'
              target='_blank'
              rel='noopener noreferrer'
            >
              <LinkedinIcon />
            </a> */}

            {twitter && (
              <a
                className="social-icon"
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
            )}
          </Social>
        </div>
      )}

      <Credit primaryText={primaryText}>
        <p>
          <Link
            className="privacy-policy"
            to="/pages/privacy-policy-pittsburgh-marathon"
          >
            Privacy Policy
          </Link>
          &copy; {new Date().getFullYear()} P3R
          <br />
          Website designed & developed by{' '}
          <a
            href="https://www.walltowall.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Wall-to-Wall Studios
          </a>
        </p>
      </Credit>
    </FooterContainer>
  )
}

export default DefaultFooter
