import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { DateTime } from 'luxon'
import DateSpan from '../DateSpan'
import Countdown from '../Countdown'
import { ButtonLink } from '../Button'

const RaceHero = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 316px;
  ${(props) =>
    props.background
      ? `background-image: url('${props.root}${props.background}');`
      : ''}
  ${(props) =>
    props.backgroundSmall
      ? `background-image: url('${props.root}${props.backgroundSmall}');`
      : ''}
  background-size: cover;
  overflow: hidden;

  @media screen and (min-width: 960px) {
    ${(props) =>
      props.background
        ? `background-image: url('${props.root}${props.background}');`
        : ''}
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-height: 800px;
  }

  & > img {
    width: 100%;
    flex: 1 1 auto;
    position: relative;
    z-index: 1;
    margin: -12px -40px 0 60px;

    @media screen and (min-width: 960px) {
      margin: 52px -40px 0 60px;
      align-self: flex-end;
      max-height: 800px;
      object-fit: contain;
      object-position: bottom center;
    }
  }

  .ExternalRaceHero__small-cutout {
    display: block;

    @media screen and (min-width: 960px) {
      display: none;
    }

    + .ExternalRaceHero__large-cutout {
      display: none;

      @media screen and (min-width: 960px) {
        display: block;
      }
    }
  }
`

const RaceHeroInner = styled.div`
  background-color: white;
  width: 68%;
  padding: 18px 0 22px 20px;
  margin-top: 26px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 600px) {
    width: 400px;
    padding: 26px 0 42px 32px;
  }

  @media screen and (min-width: 960px) {
    width: 530px;
    padding: 26px 0 62px 60px;
    margin: 110px 0 100px;
  }

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 220 370'%3E%3Cdefs%3E%3Cstyle%3E.a91d417c-38f8-444c-854c-0735b787653f%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cg id='bec9e997-cb2c-448b-ae0e-a64c09bb3a76' data-name='Layer 2'%3E%3Cg id='e77b4512-f89f-466a-b265-0035d86ebbce' data-name='Layer 1'%3E%3Cpolygon class='a91d417c-38f8-444c-854c-0735b787653f' points='220 0 0 0 0 370 220 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  & h5 {
    font-size: 10px;
    font-size: 0.625rem;
    margin-bottom: 8px;
    color: black;
    text-transform: none;

    @media screen and (min-width: 450px) {
      font-size: 14px;
      font-size: 0.875rem;
    }

    @media screen and (min-width: 600px) {
      font-size: 22px;
      font-size: 1.375rem;

      &.right {
        text-align: right;
      }
    }

    span {
      display: block;
    }
  }

  & h1 {
    margin-bottom: 8px;
    margin-right: -32px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 449px) {
      font-size: 26px;
      font-size: 1.625rem;
    }

    @media screen and (min-width: 600px) {
      margin-bottom: 25px;
      margin-right: -60px;
    }
  }

  & a {
    margin-top: 13px;
  }

  .ExternalRaceHero__sponsor-logo {
    width: 140px;
    object-fit: contain;
    object-position: bottom left;
    margin-bottom: 16px;
    flex: 0 0 auto;

    @media screen and (min-width: 600px) {
      width: 140px;
      margin-right: -120px;
      margin-left: 20px;
    }

    @media screen and (min-width: 768px) {
      width: 200px;
    }
  }
`

const EventTitle = styled.div`
  @media screen and (min-width: 600px) {
    display: flex;
  }
`

const ExternalRaceHero = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block
  const { colors } = props

  const [parsedStart, setParsedStart] = useState(null)
  const [parsedEnd, setParsedEnd] = useState(null)

  useEffect(() => {
    if (settings.event_start) {
      setParsedStart(
        DateTime.fromFormat(settings.event_start, 'MMMM d, yyyy h:mm a')
      )
    }

    if (settings.event_end) {
      setParsedEnd(
        DateTime.fromFormat(settings.event_end, 'MMMM d, yyyy h:mm a')
      )
    }
  }, [])

  return (
    <RaceHero
      root={context.root}
      background={
        settings.background_image ? settings.background_image.image : ''
      }
      backgroundSmall={
        settings.mobile_background_image
          ? settings.mobile_background_image.image
          : ''
      }
    >
      <RaceHeroInner>
        {settings.event_title && (
          <EventTitle>
            <h5 className={settings.sponsor_logo && 'right'}>
              {settings.event_title}
              {settings.sponsor_logo && <span>presented by</span>}
            </h5>

            {settings.sponsor_logo && (
              <img
                src={`${context.root}${settings.sponsor_logo.image}`}
                alt={settings.sponsor_name}
                className="ExternalRaceHero__sponsor-logo"
              />
            )}
          </EventTitle>
        )}

        {DateTime.local() < parsedStart ? (
          <React.Fragment>
            <DateSpan start={parsedStart} end={parsedEnd} />

            <Countdown
              start={parsedStart}
              color={colors.countdownBackground || colors.primary}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {settings.fallback_heading && <h1>{settings.fallback_heading}</h1>}

            {settings.fallback_link && (
              <ButtonLink
                href={settings.fallback_link.url}
                color={colors.button}
                background={colors.primary}
              >
                {settings.fallback_link_text
                  ? settings.fallback_link_text
                  : 'Learn More'}
              </ButtonLink>
            )}
          </React.Fragment>
        )}
      </RaceHeroInner>
      {settings.mobile_image_cutout && (
        <img
          src={`${context.root}${settings.mobile_image_cutout.image}`}
          alt={settings.image_cutout_alt}
          className="ExternalRaceHero__small-cutout"
        />
      )}

      {settings.image_cutout && (
        <img
          src={`${context.root}${settings.image_cutout.image}`}
          alt={settings.image_cutout_alt}
          className="ExternalRaceHero__large-cutout"
        />
      )}
    </RaceHero>
  )
}

export default ExternalRaceHero
