import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import SVGInject from '@iconfu/svg-inject'

const ContentHeroContainer = styled.div`
  padding: 50px 7.3% 27px;
  position: relative;
  display: flex;
  align-items: flex-end;
  min-height: 130px;
  overflow: hidden;

  @media screen and (min-width: 500px) {
    min-height: 200px;
    padding: 50px 8.3% 78px;
  }

  @media screen and (min-width: 960px) {
    min-height: 300px;
  }

  ${(props) => `
    ${
      props.backgroundImage
        ? `
      &::before {
        content: '';
        width: 120%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('${props.backgroundImage}') center no-repeat;
        background-size: cover;
        background-position: top 24% left 0;

        @media screen and (min-width: 768px) {
          width: 100%;
        }

        ${
          props.overlay
            ? `
          filter: grayscale(100%);
          background-position: center;
        `
            : ''
        }
      }
    `
        : ''
    }
    background-color: ${props.background};

    ${
      props.overlay
        ? `
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: ${props.background};
        opacity: 0.65;
      }
    `
        : ''
    }
  `}

  & img, & svg {
    position: absolute;
    top: 0;
    right: -18%;
    width: 25%;
    height: 100%;
    object-fit: cover;

    @media screen and (min-width: 500px) {
      width: 35%;
    }

    @media screen and (min-width: 500px) {
      right: -14%;
    }

    @media screen and (min-width: 960px) {
      right: -10%;
    }
  }

  & img {
    object-position: left center;
    width: 50%;
    right: 0;

    @media screen and (min-width: 500px) {
      width: 64%;
    }

    @media screen and (min-width: 600px) {
      width: 50%;
    }

    @media screen and (min-width: 960px) {
      width: 43%;
    }

    @media screen and (min-width: 1400px) {
      width: 37%;
    }
  }

  & svg {
    width: auto;
    // width: 56%;
    opacity: 0.45;
    object-position: right center;

    * {
      fill: white;
    }
  }

  & h1 {
    font-size: 40px;
    font-size: 2.5rem;
    margin: 0;
    max-width: 100px;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 768px) {
      max-width: 100%;
    }

    @media screen and (min-width: 960px) {
      font-size: 50px;
      font-size: 3.125rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 75px;
      font-size: 4.6875rem;
    }

    @media screen and (min-width: 1440px) {
      font-size: 96px;
      font-size: 6rem;
    }

    ${(props) => `
      color: ${props.color ? props.color : 'white'}
    `}
  }

  & p {
    position: relative;
    z-index: 2;
  }
`

const ContentHero = (props) => {
  const context = useContext(SiteContext)
  const { block, background, linkColor, color, marathon } = props
  const { background_image, color_overlay, heading, race_badge } =
    block.settings

  useEffect(() => {
    if (document.querySelectorAll('img.injectable').length) {
      SVGInject(document.querySelectorAll('img.injectable'), {
        afterLoad: (svg) => {
          if (svg) {
            svg.setAttribute('preserveAspectRatio', 'xMinYMid slice')
          }
        },
      })
    }
  }, [])

  return (
    <ContentHeroContainer
      backgroundImage={
        background_image && !race_badge
          ? `${context.root}${background_image.image}`
          : null
      }
      background={background}
      color={linkColor || color}
      overlay={color_overlay}
      marathon={marathon}
    >
      {race_badge && (
        <img
          src={`${context.root}${race_badge.image}`}
          className={marathon ? 'injectable' : ''}
        />
      )}
      <h1>{heading}</h1>
    </ContentHeroContainer>
  )
}

export default ContentHero
