import React, { useContext, useEffect, useRef, useState } from 'react'
import SiteContext from '../../../state/context'
import styled from 'styled-components'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import water from '../../../images/icon_water.png'
import aid from '../../../images/icon_aid.png'
import * as turf from '@turf/turf'
import CourseChart from './CourseChart'
import Legend from './Legend'

const CourseMapContainer = styled.section`
  padding: 0 8.3%;
  margin-top: 20px;
  position: relative;

  > img {
    width: 176px;
    position: absolute;
    top: -32px;
    right: calc(8.3vw - 34px);
    z-index: 3;
  }
`

const Map = styled.div`
  height: 570px;
`

const CourseMap = (props) => {
  const context = useContext(SiteContext)
  const { block, mapboxToken } = props
  const { data, logo } = block.settings

  const mapContainer = useRef(null)
  const [mapState, setMapState] = useState({
    lng: -79.962,
    lat: 40.4504,
    zoom: 12,
  })
  const [legendOpen, setLegendOpen] = useState(false)
  const [elevations, setElevations] = useState({})

  useEffect(() => {
    mapboxgl.accessToken = mapboxToken

    if (mapContainer.current) {
      const mapInit = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/browntown412/ckapox49f2aff1it7xuzv3ps1',
        center: [mapState.lng, mapState.lat],
        zoom: mapState.zoom,
        attributionControl: false,
      })

      // Add zoom and rotation controls to the map.
      mapInit.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

      // disable map zoom when using scroll
      mapInit.scrollZoom.disable()

      mapInit.on('move', () => {
        setMapState({
          lng: mapInit.getCenter().lng.toFixed(4),
          lat: mapInit.getCenter().lat.toFixed(4),
          zoom: mapInit.getZoom().toFixed(2),
        })
      })

      mapInit.on('load', () => {
        if (data) {
          mapInit.addSource('race-data', {
            type: 'geojson',
            data: `${context.root}${data.image}`,
          })

          // test Strava Data
          mapInit.addLayer({
            id: 'strava',
            type: 'line',
            source: 'race-data',
            paint: {
              'line-color': '#56B924',
              'line-width': 3,
            },
            filter: [
              'all',
              ['==', '$type', 'LineString'],
              ['==', 'type', 'Ride'],
            ],
          })

          // 1st part
          mapInit.addLayer({
            id: 'part-1',
            type: 'line',
            source: 'race-data',
            paint: {
              'line-color': '#56B924',
              'line-width': 3,
            },
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'part', 1]],
          })

          // 2nd part
          mapInit.addLayer({
            id: 'part-2',
            type: 'line',
            source: 'race-data',
            paint: {
              'line-color': '#FFC425',
              'line-width': 3,
            },
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'part', 2]],
          })

          // 3rd part
          mapInit.addLayer({
            id: 'part-3',
            type: 'line',
            source: 'race-data',
            paint: {
              'line-color': '#E87270',
              'line-width': 3,
            },
            filter: ['all', ['==', '$type', 'LineString'], ['==', 'part', 3]],
          })

          // starting line
          mapInit.addLayer({
            id: 'start',
            type: 'circle',
            source: 'race-data',
            paint: {
              'circle-color': '#56B924',
            },
            filter: ['all', ['==', '$type', 'Point'], ['==', 'type', 'start']],
          })

          // finish line
          mapInit.addLayer({
            id: 'end',
            type: 'circle',
            source: 'race-data',
            paint: {
              'circle-color': '#E87270',
            },
            filter: ['all', ['==', '$type', 'Point'], ['==', 'type', 'end']],
          })

          // 1st part mile marker circles
          mapInit.addLayer({
            id: 'miles-circle',
            type: 'circle',
            source: 'race-data',
            paint: {
              'circle-color': '#56B924',
              'circle-radius': 10,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#ffffff',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 1],
            ],
          })

          // 2nd part mile marker circles
          mapInit.addLayer({
            id: 'miles-circle-2',
            type: 'circle',
            source: 'race-data',
            paint: {
              'circle-color': '#FFC425',
              'circle-radius': 10,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#ffffff',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 2],
            ],
          })

          // 3rd part mile marker circles
          mapInit.addLayer({
            id: 'miles-circle-3',
            type: 'circle',
            source: 'race-data',
            paint: {
              'circle-color': '#E87270',
              'circle-radius': 10,
              'circle-stroke-width': 1,
              'circle-stroke-color': '#ffffff',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 3],
            ],
          })

          // 1st part mile marker text
          mapInit.addLayer({
            id: 'miles',
            type: 'symbol',
            source: 'race-data',
            layout: {
              'text-allow-overlap': true,
              'text-field': ['get', 'mile'],
              'text-font': ['Open Sans Bold'],
              'text-size': 11,
            },
            paint: {
              'text-color': '#ffffff',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 1],
            ],
          })

          // 2nd part mile marker text
          mapInit.addLayer({
            id: 'miles-2',
            type: 'symbol',
            source: 'race-data',
            layout: {
              'text-allow-overlap': true,
              'text-field': ['get', 'mile'],
              'text-font': ['Open Sans Bold'],
              'text-size': 11,
            },
            paint: {
              'text-color': '#000000',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 2],
            ],
          })

          // 3rd part mile marker text
          mapInit.addLayer({
            id: 'miles-3',
            type: 'symbol',
            source: 'race-data',
            layout: {
              'text-allow-overlap': true,
              'text-field': ['get', 'mile'],
              'text-font': ['Open Sans Bold'],
              'text-size': 11,
            },
            paint: {
              'text-color': '#ffffff',
            },
            filter: [
              'all',
              ['==', '$type', 'Point'],
              ['has', 'mile'],
              ['==', 'part', 3],
            ],
          })

          // Create a popup, but don't add it to the map yet.
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          })

          // Fluid stations
          mapInit.loadImage(water, (error, image) => {
            if (error) throw error
            mapInit.addImage('water', image)

            mapInit.addLayer({
              id: 'water-stations',
              type: 'symbol',
              source: 'race-data',
              layout: {
                'icon-allow-overlap': true,
                'icon-image': 'water',
                'icon-size': 0.5,
              },
              filter: [
                'all',
                ['==', '$type', 'Point'],
                ['==', 'type', 'water'],
              ],
            })

            mapInit.on('mouseenter', 'water-stations', (e) => {
              // Change the cursor style as a UI indicator.
              mapInit.getCanvas().style.cursor = 'pointer'

              const coordinates = e.features[0].geometry.coordinates.slice()
              const description = 'Fluid Station'

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
              }

              // Populate the popup and set its coordinates
              // based on the feature found.
              popup.setLngLat(coordinates).setHTML(description).addTo(mapInit)
            })

            mapInit.on('mouseleave', 'water-stations', () => {
              mapInit.getCanvas().style.cursor = ''
              popup.remove()
            })
          })

          // Aid stations
          mapInit.loadImage(aid, (error, image) => {
            if (error) throw error
            mapInit.addImage('aid', image)

            mapInit.addLayer({
              id: 'aid-stations',
              type: 'symbol',
              source: 'race-data',
              layout: {
                'icon-allow-overlap': true,
                'icon-image': 'aid',
                'icon-size': 0.5,
              },
              filter: ['all', ['==', '$type', 'Point'], ['==', 'type', 'aid']],
            })

            mapInit.on('mouseenter', 'aid-stations', (e) => {
              // Change the cursor style as a UI indicator.
              mapInit.getCanvas().style.cursor = 'pointer'

              const coordinates = e.features[0].geometry.coordinates.slice()
              const description = 'Aid Station'

              // Ensure that if the map is zoomed out such that multiple
              // copies of the feature are visible, the popup appears
              // over the copy being pointed to.
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
              }

              // Populate the popup and set its coordinates
              // based on the feature found.
              popup.setLngLat(coordinates).setHTML(description).addTo(mapInit)
            })

            mapInit.on('mouseleave', 'aid-stations', () => {
              mapInit.getCanvas().style.cursor = ''
              popup.remove()
            })
          })

          // Relay Exchanges
          mapInit.addLayer({
            id: 'relays',
            type: 'symbol',
            source: 'race-data',
            layout: {
              'text-allow-overlap': true,
              'text-field': ['format', 'RE', ['get', 'relay']],
              'text-font': ['Open Sans Bold'],
              'text-size': 12,
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 5,
            },
            filter: ['all', ['==', '$type', 'Point'], ['has', 'relay']],
          })
        }
      })
    }

    if (!elevations.length && data.image) {
      fetch(`${context.root}${data.image}`)
        .then((response) => response.json())
        .then((data) => {
          const lines = data.features.filter(
            (feature) => feature.geometry.type === 'LineString'
          )
          const updatedElevations = [...elevations]
          let currentDistance = 0

          lines.forEach((line) => {
            line.geometry.coordinates.forEach((coord, index) => {
              if (coord[2]) {
                if (index > 0) {
                  const measureLine = {
                    type: 'Feature',
                    geometry: {
                      type: 'LineString',
                      coordinates: [
                        coord,
                        line.geometry.coordinates[index - 1],
                      ],
                    },
                  }

                  currentDistance += turf.length(measureLine, {
                    units: 'miles',
                  })
                }

                updatedElevations.push({
                  height: coord[2] * 3.28084,
                  distance: currentDistance,
                })
              }
            })
          })

          setElevations(updatedElevations)
        })
    }
  }, [mapContainer])

  const toggleLegend = () => {
    setLegendOpen(!legendOpen)
  }

  return (
    <React.Fragment>
      <CourseMapContainer>
        {logo && <img src={`${context.root}${logo.image}`} />}

        <Legend open={legendOpen} toggle={toggleLegend} />

        <Map ref={mapContainer} />
      </CourseMapContainer>

      {elevations.length > 0 && (
        <CourseChart elevations={elevations} height={172} />
      )}
    </React.Fragment>
  )
}

export default CourseMap
