import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import Swiper from 'react-id-swiper'

import { PartnersContainer, PartnersList, PartnerSlide } from './Partners'

const PartnersFilters = styled.ul`
  margin: -10px 0 -20px;
  padding: 0;
  list-style: none;
  display: flex;
  overflow: auto;
  width: 100%;

  li {
    margin-right: 22px;

    @media screen and (min-width: 768px) {
      margin-right: 44px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  button {
    appearance: none;
    background: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    font-family: soleil, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 16px;
    font-size: 1rem;
    color: #999;
    line-height: 1.125;
    text-transform: uppercase;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      font-size: 22px;
      font-size: 1.375rem;
    }

    &:hover,
    &:focus,
    &.active {
      outline: none;
      
      ${(props) => `
        border-bottom: 3px solid ${props.linkUnderlineColor};
      `}
  }
`

const PartnerTiers = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block
  const { logos } = settings
  const { colors } = props

  const [tiers, setTiers] = useState([
    {
      name: 'All',
      selected: true,
    },
  ])
  const [filteredLogos, setFilteredLogos] = useState(logos)

  useEffect(() => {
    const updatedTiers = [...tiers]

    logos.forEach((item) => {
      const foundTier = updatedTiers.find((t) => t.name === item.tier)

      if (!foundTier) {
        updatedTiers.push({
          name: item.tier,
          selected: false,
        })
      }
    })

    setTiers(updatedTiers)
  }, [])

  const params = {
    centeredSlides: true,
    slidesPerView: 'auto',
    spaceBetween: 32,
    rebuildOnUpdate: true,
  }

  const changeTier = (index) => {
    const updatedTiers = [...tiers]
    updatedTiers.forEach((tier) => (tier.selected = false))

    const selectedTier = updatedTiers[index]
    selectedTier.selected = true

    setTiers(updatedTiers)

    if (selectedTier.name === 'All') {
      setFilteredLogos(logos)
    } else {
      setFilteredLogos(logos.filter((logo) => logo.tier === selectedTier.name))
    }
  }

  return (
    <React.Fragment>
      {(filteredLogos || []).length > 0 && (
        <PartnersContainer>
          <h2>Partners</h2>

          <PartnersFilters
            linkUnderlineColor={colors.linkUnderline || '#ffc425'}
          >
            {tiers.map((tier, index) => (
              <li key={tier.name}>
                <button
                  className={tier.selected ? 'active' : ''}
                  onClick={() => changeTier(index)}
                >
                  {tier.name}
                </button>
              </li>
            ))}
          </PartnersFilters>

          <PartnersList>
            <Swiper {...params}>
              {filteredLogos.map((logo, index) => (
                <PartnerSlide key={index}>
                  <div>
                    {logo.icon.data &&
                      (logo.link.data ? (
                        <a
                          href={logo.link.data.url}
                          target={logo.icon.data.new_tab ? '_blank' : ''}
                        >
                          <img
                            src={`${context.root}${logo.icon.data.image}`}
                            alt={logo.icon.data.description}
                          />
                        </a>
                      ) : (
                        <img
                          src={`${context.root}${logo.icon.data.image}`}
                          alt={logo.icon.data.description}
                        />
                      ))}
                  </div>
                </PartnerSlide>
              ))}
            </Swiper>
          </PartnersList>
        </PartnersContainer>
      )}
    </React.Fragment>
  )
}

export default PartnerTiers
