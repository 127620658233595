import styled from 'styled-components'

const Button = styled.button`
  ${(props) =>
    `
    background-color: ${props.background};
    color: ${props.color};
    `}
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 800;
  font-family: inherit;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 50px 12px;
  border-radius: 4px;
  border: 0;
  transition: background-color 0.21s ease-out;

  &:hover,
  &:focus {
    background-color: #555;
  }
`

const ButtonLink = styled.a`
  ${(props) =>
    `
    background-color: ${props.background};
    color: ${props.color};
    `}
  display: inline-block;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 800;
  font-family: inherit;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 50px 12px;
  border-radius: 4px;
  transition: background-color 0.21s ease-out;

  &:hover,
  &:focus {
    background-color: #555;
  }
`

export { Button, ButtonLink }
