import React, { useContext } from 'react'
import styled from 'styled-components'
import UnderlinedLink from '../UnderlinedLink'
import SiteContext from '../../state/context'

const CardsContainer = styled.div`
  margin: 40px 28px 12px;

  @media screen and (min-width: 960px) {
    margin: 72px 9.5% 28px;
  }
`

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: 0 15px 30px 0 rgba(184, 184, 184, 0.5);
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
  border-radius: 4px;

  @media screen and (min-width: 768px) {
    display: flex;
  }

  img {
    display: block;
    margin: 0 auto;
    min-height: 3.5rem;

    @media screen and (min-width: 768px) {
      width: 47%;
      flex: 1 0 auto;
      object-fit: cover;
      font-family: 'object-fit: cover';
      max-width: 342px;
      max-width: 21.375rem;
    }
  }
`

const CardContainer = styled.div`
  background-color: #000;
  color: #fff;
  padding: 23px;
  padding: 1.4375rem;

  @media screen and (min-width: 768px) {
    width: 53%;
    flex: 1 0 auto;
  }

  @media screen and (min-width: 1200px) {
    padding: 40px 15% 40px 56px;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    @media screen and (min-width: 768px) {
      margin-bottom: 35px;
      margin-bottom: 2.1875rem;
    }
  }
`

const Cards = (props) => {
  const context = useContext(SiteContext)
  const { block, colors } = props
  const { cards } = block.settings

  return (
    <CardsContainer>
      {cards.map((card, index) => (
        <Card key={index}>
          {(card.image || {}).data && (
            <img
              src={`${context.root}${card.image.data.image}`}
              alt={card.image.alt_desc}
            />
          )}

          <CardContainer>
            {card.title && <h3>{card.title}</h3>}
            {card.content && <p>{card.content}</p>}

            {(card.link || {}).data && (
              <UnderlinedLink
                href={card.link.data.url}
                color="white"
                underlineColor="white"
              >
                {card.link.custom_text || 'Learn More'}
              </UnderlinedLink>
            )}
          </CardContainer>
        </Card>
      ))}
    </CardsContainer>
  )
}

export default Cards
