import React from 'react'
import styled from 'styled-components'
import relay from '../../../images/icon_relay.svg'
import water from '../../../images/icon_water.png'
import aid from '../../../images/icon_aid.png'
import { ReactComponent as Arrow } from '../../../images/arrow_show.svg'

const LegendContainer = styled.div`
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 8.3vw;
  height: 100%;
  overflow: auto;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 3;
  transition: width 0.21s ease-in-out;
  padding: 20px 26px;

  > div {
    width: 218px;
    padding: 35px 8px 0;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity 0.21s ease-in-out, transform 0.21s ease-in-out;
  }

  &.open {
    width: 270px;

    > div {
      transform: translateX(0);
      opacity: 1;
    }
  }

  button {
    appearance: none;
    border: 0;
    background: none;
    width: 94px;
    text-align: left;
    line-height: 1.17;
    font-family: inherit;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 40px 0 0;
    position: relative;
    transition: opacity 0.21s ease-out;
    cursor: pointer;

    @media screen and (min-width: 1300px) {
    }

    svg {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
      transition: transform 0.21s ease-in-out;
    }

    &.open {
      svg {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    &:hover,
    &:focus {
      outline: none;
      opacity: 0.65;
    }
  }

  hr {
    border: 0;
    margin: 20px 0;
  }
`

const Icon = styled.p`
  font-size: 13px;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  img {
    width: 20px;
    margin-right: 16px;

    &.large {
      width: 24px;
      margin-left: -2px;
      margin-right: 14px;
    }
  }
`

const Circle = styled.span`
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 18px;

  ${({ color }) => `
    background-color: ${color};
  `}
`

const Part = styled.p`
  font-size: 13px;
  font-size: 0.8125rem;
  margin-top: 20px;
`

const Path = styled.span`
  display: block;
  width: 75px;
  height: 4px;
  margin-bottom: 6px;
  ${({ color }) => `
    background-color: ${color};
  `}
`

const Markers = styled.span`
  display: flex;
  margin-bottom: 8px;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 600;
    margin-right: 12px;
    color: white;
    padding-bottom: 2px;

    &:nth-child(1) {
      background-color: #56b924;
    }

    &:nth-child(2) {
      background-color: #ffc425;
      color: black;
    }

    &:nth-child(3) {
      background-color: #25e882;
    }
  }
`

const Legend = (props) => {
  const { open, toggle } = props

  return (
    <LegendContainer className={open ? 'open' : ''}>
      <button className={open ? 'open' : ''} onClick={toggle}>
        {open ? 'hide' : 'show'} legend
        <Arrow />
      </button>

      <div>
        <Icon>
          <Circle color="#56B924" />
          Start
        </Icon>

        <Icon>
          <Circle color="#E87270" />
          Finish
        </Icon>

        <hr />

        <Part>
          <Path color="#56B924" />
          1st Part of the Marathon Course
        </Part>

        <Part>
          <Path color="#FFC425" />
          2nd Part of the Marathon Course
        </Part>

        <Part>
          <Path color="#E87270" />
          3rd Part of the Marathon Course
        </Part>

        <hr />

        <Part>
          <Markers>
            <span>3</span>
            <span>13</span>
            <span>23</span>
          </Markers>
          Mile Markers
        </Part>

        <hr />

        <Icon>
          <img src={aid} />
          Aid Station
        </Icon>

        <Icon>
          <img className="large" src={water} />
          Fluid Station
        </Icon>

        <Icon>
          <img src={relay} />
          Relay Exchange
        </Icon>
      </div>
    </LegendContainer>
  )
}

export default Legend
