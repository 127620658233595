import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import { DateTime } from 'luxon'
import UnderlinedLink from '../UnderlinedLink'

const NewsAndEvents = styled.section`
  display: flex;
  flex-direction: column;
  padding: 45px 8.3% 35px;

  ${(props) => `
    background-color: ${props.background};
  `}

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 72px 8.3%;
  }
`

const FeaturedPost = styled.div`
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 768px) {
    width: 50%;
    flex: 0 0 auto;
    padding-right: 7vw;
  }
`

const FeaturedPostTitle = styled.h2`
  font-size: 34px;
  font-size: 2.125rem;
  margin-bottom: 20px;

  ${(props) => `
    color: ${props.color};
  `}

  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 960px) {
    font-size: 48px;
    font-size: 3rem;
  }

  @media screen and (min-width: 1080px) {
    font-size: 52px;
    font-size: 3.25rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 56px;
    font-size: 3.5rem;
  }

  @media screen and (min-width: 1440px) {
    font-size: 62px;
    font-size: 3.875rem;
  }
`

const Card = styled.a`
  display: block;
  text-decoration: none;

  ${(props) => `
    color: ${props.primaryText || props.color};
  `}

  ${(props) => `
    background-color: ${props.background};
  `}
`

const CardImage = styled.figure`
  margin: 0;
`

const CardDetails = styled.div`
  padding: 17.5px 20px 26px;

  @media screen and (min-width: 768px) {
    padding: 20px 28px 28px;
  }

  & h3 {
    margin-bottom: 8px;
  }

  & p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      font-size: 22px;
      font-size: 1.375rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const CardDate = styled.div`
  font-size: 13px;
  font-size: 0.8125rem;
  margin-bottom: 8px;

  & span {
    font-size: 13px;
    font-size: 0.625rem;
    font-weight: 400;

    &:first-child {
      margin-right: 22px;
    }
  }
`

const UpcomingEvents = styled.div`
  flex: 0 0 auto;

  @media screen and (min-width: 768px) {
    width: 60%;
    padding-right: 8.3vw;
  }

  & a {
    margin-top: 72px;

    @media screen and (min-width: 768px) {
      margin-top: 66px;
    }
  }
`

const UpcomingEvent = styled.div`
  display: grid;
  border-bottom: 1px solid #d9d9d9;
  padding-top: 10px;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;

  > h4 {
    grid-column: 1 / 2;
    padding-right: 32px;

    @media screen and (min-width: 768px) {
      padding-right: 28px;
      grid-row: 1 / 3;
      display: flex;
      align-items: center;
    }
  }

  ${(props) => `
    color: ${props.color};
  `}

  & h4 {
    font-size: 42px;
    font-size: 2.625rem;
    flex: 0 0 auto;
    font-weight: 800;
    margin-bottom: 0;
    margin-right: 13.5%;

    @media screen and (min-width: 768px) {
      font-size: 38px;
      font-size: 2.375rem;
    }

    @media screen and (min-width: 960px) {
      font-size: 66px;
      font-size: 4.125rem;
    }

    @media screen and (min-width: 1440px) {
      margin-right: 6%;
    }
  }

  & h3 {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 700;

    @media screen and (min-width: 768px) {
      font-size: 30px;
      font-size: 1.875rem;
    }
  }

  & a {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    color: inherit;
    text-decoration: none;
    margin-top: 16px;

    @media screen and (min-width: 768px) {
      margin-top: 5px;
      grid-column: 2 / 3;
      grid-row: initial;
    }
  }

  & div {
    grid-column: 2 / 3;
    padding-top: 6px;

    & h4 {
      font-size: 12px;
      font-size: 0.75rem;
      font-weight: 800;
      margin: 5px 0px;
      text-transform: uppercase;

      @media screen and (min-width: 768px) {
        font-size: 22px;
        font-size: 1.375rem;
        margin-top: 0px;
      }
    }

    & h4:first-of-type {
      margin-bottom: 0px;

      @media screen and (min-width: 960px) {
        margin-top: 15px;
      }
    }
  }
`

const displayDate = (isoDate) => {
  const date = DateTime.fromISO(isoDate).toFormat('LL.dd.yy')

  return <span> {date} </span>
}

const NewsEvents = (props) => {
  const context = useContext(SiteContext)
  const { featuredPost, upcoming } = props.block
  const { primary, secondary, newsEvents, link, linkUnderline } = props.colors

  const displayEventDate = (date) => {
    const formattedDate = DateTime.fromISO(date).toFormat('LL/dd')

    return (
      <h4
        style={{
          color: secondary,
        }}
      >
        {formattedDate}
      </h4>
    )
  }

  const displayEventTime = (date) => {
    const formattedDate = DateTime.fromISO(date).toFormat('h:mm a')

    return <h4> {formattedDate} </h4>
  }

  return (
    <NewsAndEvents background={newsEvents || 'white'}>
      {featuredPost && (
        <FeaturedPost>
          <FeaturedPostTitle color={newsEvents ? 'white' : 'black'}>
            Blog & News
          </FeaturedPostTitle>
          <Card
            href={`/blog/${featuredPost.slug}`}
            background={primary}
            color={newsEvents}
          >
            {featuredPost.image ? (
              <CardImage>
                <img src={`${context.root}${featuredPost.image}`} />
              </CardImage>
            ) : props.newsEventsBackgroundFallback ? (
              <CardImage>
                <img src={props.newsEventsBackgroundFallback} />
              </CardImage>
            ) : null}

            <CardDetails>
              <CardDate>
                <span>
                  {featuredPost.published &&
                    displayDate(featuredPost.published)}
                </span>
                <span>
                  {featuredPost.categories.map((category, index) => {
                    if (index > 0) {
                      return `, ${category}`
                    } else {
                      return category
                    }
                  })}
                </span>
              </CardDate>
              <h3> {featuredPost.title} </h3>
              {featuredPost.excerpt && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: featuredPost.excerpt,
                  }}
                />
              )}
            </CardDetails>
          </Card>

          <UnderlinedLink
            href="/blog"
            color={link || 'white'}
            underlineColor={linkUnderline || 'white'}
          >
            All Blog Posts
          </UnderlinedLink>

          <UnderlinedLink
            href="/news"
            color={link || 'white'}
            underlineColor={linkUnderline || 'white'}
          >
            All Press Releases
          </UnderlinedLink>
        </FeaturedPost>
      )}
      {upcoming && (
        <UpcomingEvents>
          <FeaturedPostTitle color={newsEvents ? 'white' : 'black'}>
            Upcoming Events
          </FeaturedPostTitle>
          {upcoming.map((event, index) => (
            <UpcomingEvent key={index} color={newsEvents ? 'white' : 'black'}>
              {event.start && displayEventDate(event.start)}
              <div>
                {event.categories.length > 0 && (
                  <h4>
                    {event.categories.map((category, index) => {
                      if (index !== 0) {
                        return `, ${category}`
                      } else {
                        return category
                      }
                    })}
                  </h4>
                )}
                {event.start && displayEventTime(event.start)}
              </div>

              <a href={event.url}>
                <h3
                  style={{
                    color: newsEvents ? 'white' : 'black',
                  }}
                >
                  {event.title}
                </h3>
              </a>
            </UpcomingEvent>
          ))}
          <UnderlinedLink href="/events"> Full Calendar </UnderlinedLink>
        </UpcomingEvents>
      )}
    </NewsAndEvents>
  )
}

export { displayDate, Card, CardDate, CardDetails, CardImage }

export default NewsEvents
