import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import SiteContext from '../../../state/context'
import Block from '../../Block'
import ContentHero from '../../blocks/ContentHero'

const EventContainer = styled.section`
  background: black;
  color: white;
  padding: 32px 21px 40px;

  @media screen and (min-width: 500px) {
    padding: 44px 8.3vw 54px;
  }

  @media screen and (min-width: 960px) {
    display: flex;
    padding: 44px 0 54px 8.3vw;
  }
`

const EventDetails = styled.div`
  @media screen and (min-width: 960px) {
    width: 56%;
    flex: 0 0 auto;
  }

  h2 {
    @media screen and (min-width: 768px) {
      font-size: 48px;
      font-size: 3rem;
    }
  }

  label {
    display: block;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px;

    @media screen and (min-width: 500px) {
      font-size: 20px;
      font-size: 1.25rem;
      margin-bottom: 9px;
    }

    ${({ color }) => `
      color: ${color};
    `}
  }

  *[class*='Copy'] {
    margin: 0;
  }

  p {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;

    @media screen and (min-width: 500px) {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }

  > div {
    margin-bottom: 24px;

    @media screen and (min-width: 500px) {
      margin-bottom: 30px;
    }

    *:last-child {
      margin-bottom: 0;
    }
  }
`

const EventImage = styled.div`
  flex: 1 1 auto;
  text-align: right;
  padding-bottom: 20px;

  @media screen and (min-width: 960px) {
    padding: 68px 0 20px;
  }

  *[class*='ImageContainer'] {
    max-width: none;
    width: calc(100% + 21px);

    @media screen and (min-width: 500px) {
      width: calc(100% + 8.3vw);
    }

    @media screen and (min-width: 960px) {
      margin-left: 82px;
      max-width: 100%;
    }
  }
`

const Event = (props) => {
  const context = useContext(SiteContext)
  const historyParams = useParams()
  const { colors, fallback, site } = props
  const { button, primary, secondary } = colors

  useEffect(() => {
    const preview = queryString.parse(location.search).preview

    context.getEvent(historyParams.id, site || 'pittsburgh-marathon', preview)
  }, [])

  return (
    <main>
      <ContentHero
        background={secondary}
        block={{
          settings: {
            heading: 'Calendar',
          },
        }}
      />

      {Object.keys(context.event).length > 0 && (
        <EventContainer>
          <EventDetails color={secondary}>
            <h2>{context.event.title}</h2>

            <div>
              <label>When</label>
              {context.event.time && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: context.event.time,
                  }}
                />
              )}
            </div>

            <div>
              <label>Where</label>
              <p>{context.event.venue}</p>
            </div>

            <div>
              <label>Details</label>

              {context.event.blocks['Details'] &&
                context.event.blocks['Details'].map((block, index) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      button,
                    }}
                  />
                ))}
            </div>
          </EventDetails>

          <EventImage>
            {context.event.blocks['Featured'] ? (
              context.event.blocks['Featured'].map((block) => (
                <Block key={block.id} data={block} />
              ))
            ) : fallback ? (
              <img src={fallback} />
            ) : null}
          </EventImage>
        </EventContainer>
      )}
    </main>
  )
}

export default Event
