import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import SiteContext from '../../../state/context'

const Results = styled.div`
  padding: 40px 21px 52px;
  max-width: 955px;
  margin: 0 auto;

  @media screen and (min-width: 500px) {
    padding: 114px 8.3vw 148px;
    max-width: calc(913px + 16.6vw);
  }

  form {
    flex-wrap: nowrap;
    margin: 0 0 16px;
    max-width: 522px;
  }

  > p {
    margin-bottom: 63px;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #999;
  }

  button {
    margin-left: -5px;
    padding: 0 10px;
    height: 48px;
    ${({ primary }) => `
      background-color: ${primary};
    `}

    ${(props) => `
      color: ${props.primaryText || 'black'};
    `}

    @media screen and (min-width: 600px) {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }

  li {
    margin-bottom: 24px;

    p {
      font-size: 18px;
      font-size: 1.125rem;
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      h4 {
        ${({ secondary }) => `
          color: ${secondary};
        `}
      }
    }

    h4 {
      color: black;
      transition: color 0.21s ease-out;
      margin-bottom: 8px;
      font-size: 16px;
      font-size: 1rem;
      font-weight: 800;
      text-transform: uppercase;
      color: #555;
    }
  }
`

const Search = (props) => {
  const context = useContext(SiteContext)
  const location = useLocation()
  const [query, setQuery] = useState('')
  const { colors, site } = props
  const { primary, secondary, primaryText } = colors

  useEffect(() => {
    const initialQuery = queryString.parse(location.search)
    setQuery(initialQuery.query)

    if (initialQuery.query) {
      context.getResults(site || 'pittsburgh-marathon', initialQuery.query)
    }
  }, [])

  return (
    <main>
      <Results
        primary={primary}
        secondary={secondary}
        primaryText={primaryText}
      >
        <form action="/search" method="get">
          <input name="query" type="text" placeholder={query} />

          <button>Search</button>
        </form>

        <p>
          Showing {context.results.length} Result
          {context.results.length === 1 ? '' : 's'}
        </p>

        <ul>
          {context.results.map((result, index) => (
            <li key={index}>
              <a href={result.url}>
                <h4>{result.title}</h4>
              </a>

              {result.meta_description && <p>{result.meta_description}</p>}
            </li>
          ))}
        </ul>
      </Results>
    </main>
  )
}

export default Search
