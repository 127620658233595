import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as P3RLogo } from '../images/logo_p3r.svg'
import { ReactComponent as Search } from '../images/icon_search.svg'

const SubHeaderContainer = styled.header`
  height: 40px;
  padding: 0 16px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (min-width: 600px) {
    padding: 0 28px;
    height: 60px;
  }

  @media screen and (min-width: 1024px) {
    padding: 0 38px;
    height: 72px;
  }

  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
    }
  }

  & li {
    margin-right: 18px;
  }

  & a {
    text-transform: uppercase;
    color: white;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    text-decoration: none;
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }

  & svg {
    display: block;
    height: 16px;

    @media screen and (min-width: 600px) {
      height: 25px;
    }

    * {
      fill: white;
    }
  }

  > a:last-child {
    margin-left: 16px;

    svg {
      opacity: 0.5;
    }
  }

  .search {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
`

const SubHeader = (props) => {
  const { menus } = props

  return (
    <SubHeaderContainer>
      {menus && (
        <nav
          dangerouslySetInnerHTML={{
            __html: menus.sub,
          }}
        />
      )}

      <Link className="search" to="/search">
        <Search />
      </Link>

      <a href="https://p3r.org" target="_blank">
        <P3RLogo />
      </a>
    </SubHeaderContainer>
  )
}

export default SubHeader
