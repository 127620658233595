import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import Collapse from '@material-ui/core/Collapse'
import UnderlinedLink from '../UnderlinedLink'
import { ReactComponent as Plus } from '../../images/icon_plus.svg'

const TopQuestionsContainer = styled.section`
  padding: 30px 21px 48px;
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 500px) {
    padding: 30px 8.3% 48px;
  }

  @media screen and (min-width: 768px) {
    padding: 234px 0 52px;
  }

  & > img {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 72%;
      position: absolute;
      top: 74px;
      left: -94px;
    }
  }
`

const TopQuestionsAccordions = styled.div`
  width: 100%;
  padding: 16px 20px 28px;
  position: relative;
  color: ${(props) => (props.darkText ? 'black' : 'white')};
  box-shadow: -4px 8px 18px rgba(0, 0, 0, 0.25);
  align-self: flex-start;

  @media screen and (min-width: 500px) {
    padding: 24px 48px 42px;
  }

  @media screen and (min-width: 768px) {
    padding: 24px 116px 42px 48px;
  }

  @media screen and (min-width: 1080px) {
    width: 670px;
  }

  ${(props) => `
    background-color: ${props.background};
  `}

  & h2 {
    margin-bottom: 12px;

    @media screen and (min-width: 500px) {
      margin-bottom: 24px;
    }
  }

  & > div:nth-of-type(n + 1) {
    margin-top: 10px;

    @media screen and (min-width: 500px) {
      margin-top: 18px;
    }
  }

  a {
    color: ${(props) => (props.darkText ? 'black' : 'white')};
    border-bottom-color: ${(props) => (props.darkText ? 'black' : 'white')};
    transition: opacity 0.21s ease-out;

    &:hover,
    &:focus {
      opacity: 0.65;
    }
  }
`

const Accordion = styled.div`
  & button {
    display: block;
    width: 100%;
    padding: 0;
    appearance: none;
    background: none;
    border: 0;
    border-radius: 0;
    color: ${(props) => (props.darkText ? 'black' : 'white')};
    font-family: inherit;
    text-align: left;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    transition: opacity 0.21s ease-out;
    padding-right: 32px;

    @media screen and (min-width: 500px) {
      font-size: 20px;
      font-size: 1.25rem;
    }

    &:hover,
    &:focus {
      outline: none;
      opacity: 0.7;
    }

    & svg {
      width: 18px;
      height: 18px;
      transition: transform 0.3s ease-out;
      position: absolute;
      top: calc(50% - 8px);
      right: 0;

      @media screen and (min-width: 500px) {
        width: 24px;
        height: 24px;
        top: calc(50% - 12px);
        right: 4px;
      }

      &.open {
        transform: rotate(-135deg);
      }

      path {
        fill: ${(props) => (props.darkText ? 'black' : 'white')};
      }
    }
  }

  & p {
    font-size: 16px;
    font-size: 1rem;
    margin-top: 12px;

    @media screen and (min-width: 500px) {
      font-size: 18px;
      font-size: 1.125rem;
      margin-top: 16px;
    }
  }
`

const TopQuestions = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block

  const [accordions, setAccordions] = useState(settings.accordions)

  return (
    <TopQuestionsContainer>
      {settings.image && <img src={`${context.root}${settings.image.image}`} />}

      <TopQuestionsAccordions
        background={props.background}
        darkText={props.darkText}
      >
        <h2>Top Questions</h2>

        {accordions.map((accordion, index) => (
          <Accordion key={index} darkText={props.darkText}>
            <button
              type="button"
              onClick={() => {
                const accordionClone = [...accordions]
                accordionClone[index].open = !accordionClone[index].open

                setAccordions(accordionClone)
              }}
            >
              {accordion.title}

              <Plus className={accordion.open ? 'open' : ''} />
            </button>

            <Collapse in={accordion.open}>
              <div
                dangerouslySetInnerHTML={{
                  __html: accordion.content.replace(
                    /(\/media)+/g,
                    `${context.root}/media`
                  ),
                }}
              />
            </Collapse>
          </Accordion>
        ))}

        {settings.link && (
          <UnderlinedLink
            href={settings.link.url}
            target={settings.link.new_tab ? '_blank' : ''}
          >
            {settings.link_text ? settings.link_text : 'All FAQs'}
          </UnderlinedLink>
        )}
      </TopQuestionsAccordions>
    </TopQuestionsContainer>
  )
}

export { Accordion, TopQuestionsAccordions }

export default TopQuestions
