export const SET_SITE = 'SET_SITE'
export const SET_PAGE = 'SET_PAGE'
export const SET_RACE = 'SET_RACE'
export const SET_EVENTS = 'SET_EVENTS'
export const SET_EVENT = 'SET_EVENT'
export const SET_FEATURED_NEWS = 'SET_FEATURED_NEWS'
export const SET_NEWS = 'SET_NEWS'
export const APPEND_NEWS = 'APPEND_NEWS'
export const SET_TOTAL = 'SET_TOTAL'
export const SET_POST = 'SET_POST'
export const SET_RESULTS = 'SET_RESULTS'

const setSite = (site, state) => {
  return { ...state, site }
}

const setPage = (page, state) => {
  return { ...state, page }
}

const setRace = (race, state) => {
  return { ...state, race }
}

const setEvents = (events, state) => {
  return { ...state, events }
}

const setEvent = (event, state) => {
  return { ...state, event }
}

const setFeaturedNews = (featuredNews, state) => {
  return { ...state, featuredNews }
}

const setNews = (news, state) => {
  return { ...state, news }
}

const setTotal = (total, state) => {
  return { ...state, total }
}

const appendNews = (news, state) => {
  let updatedNews = [...state.news]
  updatedNews = updatedNews.concat(news)

  return { ...state, news: updatedNews }
}

const setPost = (post, state) => {
  return { ...state, post }
}

const setResults = (results, state) => {
  return { ...state, results }
}

export const siteReducer = (state, action) => {
  switch (action.type) {
    case SET_SITE:
      return setSite(action.site, state)
    case SET_PAGE:
      return setPage(action.page, state)
    case SET_RACE:
      return setRace(action.race, state)
    case SET_EVENTS:
      return setEvents(action.events, state)
    case SET_EVENT:
      return setEvent(action.event, state)
    case SET_FEATURED_NEWS:
      return setFeaturedNews(action.featuredNews, state)
    case SET_NEWS:
      return setNews(action.news, state)
    case APPEND_NEWS:
      return appendNews(action.news, state)
    case SET_TOTAL:
      return setTotal(action.total, state)
    case SET_POST:
      return setPost(action.post, state)
    case SET_RESULTS:
      return setResults(action.results, state)
    default:
      return state
  }
}
