import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import SiteContext from '../../../state/context'
import styled from 'styled-components'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { DateTime } from 'luxon'
import { ReactComponent as CalendarIcon } from '../../../images/view_calendar.svg'
import { ReactComponent as ListIcon } from '../../../images/view_list.svg'
import { useDebounce } from '../../../util'
import ContentHero from '../../blocks/ContentHero'

const EventsContainer = styled.section`
  padding-bottom: 36px;

  @media screen and (min-width: 600px) {
    padding: 0 56px 100px;
  }

  .fc {
    background-color: #f7f7f7;
    padding: 32px 21px 21px;

    @media screen and (min-width: 600px) {
      padding: 62px 46px 64px;
    }

    @media screen and (min-width: 960px) and (max-width: 1199px) {
      padding: 62px 21px 64px;
    }

    &-row {
      min-height: 24px !important;
    }

    &-toolbar {
      margin-bottom: 36px;

      @media screen and (min-width: 450px) {
        margin-bottom: 58px;
      }

      @media screen and (min-width: 768px) {
        padding: 0 62px;
      }
    }

    h2 {
      font-size: 24px;
      font-size: 1.5rem;
      color: #333;

      @media screen and (min-width: 450px) {
        font-size: 38px;
        font-size: 2.375rem;
      }
    }

    &-button {
      padding: 0;
      background: none;
      border-radius: 0;
      border: 0;
      transition: opacity 0.21s ease-out;

      &:hover,
      &:focus {
        opacity: 0.65;
        border: 0;
        background: none;
      }
    }

    &-icon-chevron-left,
    &-icon-chevron-right {
      font-size: 40px;
      ${({ color }) => `
        color: ${color};
      `}
    }

    &-scroller {
      height: auto !important;
    }

    &-head-container {
      border: 0;
    }

    &-widget-header > .fc-widget-header {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 13px;
    }

    &-widget-content {
      border: 0;
    }

    &-list-view {
      display: none;
    }

    &-event-container {
      display: none;
    }

    &-bg {
      opacity: 0;
    }

    &-day-header {
      border: 0;
      font-size: 20px;
      font-size: 1.25rem;
      font-weight: 700;
      color: #333;

      @media screen and (min-width: 500px) {
        font-size: 36px;
        font-size: 2.25rem;
      }
    }

    &-day-top {
      color: #bdbdbd;
      box-sizing: border-box;
      padding-bottom: 5px;
    }

    .has-event {
      color: #333;
    }

    .active span {
      ${({ color }) => `
        background-color: ${color} !important;
      `}
      color: white !important;
      border-radius: 3px;
    }

    &-day-number {
      font-size: 20px;
      font-size: 1.25rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      width: 36px;
      float: none !important;
      box-sizing: border-box;
      padding-bottom: 5px;
      margin: 0 auto;

      @media screen and (min-width: 500px) {
        height: 58px;
        width: 58px;
        font-size: 32px;
        font-size: 2rem;
      }

      @media screen and (min-width: 768px) {
        height: 76px;
        width: 76px;
        font-size: 36px;
        font-size: 2.25rem;
      }

      @media screen and (min-width: 960px) and (max-width: 1199px) {
        font-size: 28px;
        font-size: 1.75rem;
        height: 52px;
        width: 52px;
      }

      @media screen and (min-width: 1400px) {
        height: 76px;
        width: 76px;
        font-size: 36px;
        font-size: 2.25rem;
      }
    }
  }

  &.list {
    .fc {
      background: none;
      padding: 62px 46px 0;
    }
  }
`

const EventFilters = styled.div`
  padding: 52px 21px 36px;

  @media screen and (min-width: 500px) {
    padding: 52px 8.3vw 36px;
  }

  @media screen and (min-width: 600px) {
    padding: 90px 0 36px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 28px;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0;
  }

  > div {
    &:first-child {
      @media screen and (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
    }

    &:last-child {
      @media screen and (min-width: 600px) {
        display: flex;
        align-items: center;
      }

      @media screen and (min-width: 960px) {
        padding-left: 30px;
        margin-top: 0;
      }
    }
  }

  label {
    display: block;
    position: relative;
    margin-bottom: 44px;

    @media screen and (min-width: 600px) {
      margin-bottom: 0;
    }

    span {
      position: absolute;
      bottom: calc(100% + 8px);
      left: 0;
      font-size: 16px;
      font-size: 1rem;
      color: #9b9b9b;
      font-weight: 700;
      text-transform: uppercase;
    }

    &.dropdown::after {
      content: '';
      border-top: 14px solid #9b9b9b;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      bottom: 16px;
      right: 13px;
      pointer-events: none;
    }
  }

  select,
  input {
    width: 100%;
    appearance: none;
    height: 48px;
    background: #f7f7f7;
    border-radius: 4px;
    border: 0;
    font-family: inherit;
    font-size: 16px;
    font-size: 1rem;
    padding: 0 16px;
  }

  select {
    padding-right: 42px;
  }

  input {
    &::placeholder {
      color #999;
      opacity: 1;
    }
  }

  button {
    opacity: 0.5;
    background: none;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 700;
    text-transform: uppercase;
    font-family: inherit;
    margin-right: 18px;
    transition: opacity 0.21s ease-out;
    cursor: pointer;
    ${({ color }) => `
      color: ${color};

      svg * {
        fill: ${color};
      }
    `}

    @media screen and (min-width: 600px) {
      display: inline-flex;
    }

    &:first-of-type {
      margin-bottom: 18px;

      @media screen and (min-width: 600px) {
        margin-bottom: 0;
      }
    }

    svg {
      margin-right: 10px;
    }

    &:hover,
    &:focus,
    &.active {
      opacity: 1;
    }
  }
`

const Calendar = styled.div`
  @media screen and (min-width: 960px) {
    ${(props) => `
      ${
        !props.fullWidth &&
        `
        display: grid;
        grid-template-columns: 1fr 1fr;
      `
      }
    `}
  }
`

const Day = styled.div`
  padding: 21px;

  @media screen and (min-width: 600px) {
    padding: 36px 0;
  }

  @media screen and (min-width: 960px) {
    padding: 36px 56px;
  }

  h2 {
    margin-left: -4px;
    margin-bottom: 2px;
    ${({ color }) => `
      color: ${color};
    `}

    @media screen and (max-width: 767px) {
      font-size: 48px;
      font-size: 3rem;
    }
  }

  h4 {
    font-size: 18px;
    font-size: 1.125rem;
    text-transform: uppercase;
    margin-top: 6px;

    @media screen and (min-width: 500px) {
      margin-top: 0;
      font-size: 24px;
      font-size: 1.5rem;
    }
  }

  > p {
    border-top: 1px solid #d8d8d8;
    padding: 14px 0 18px;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

const List = styled.div`
  max-width: 960px;
  margin: 0 auto;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  > p {
    border-top: 1px solid #d8d8d8;
    padding: 14px 0 18px;
    margin: 0;
  }
`

const Event = styled.li`
  border-top: 1px solid #d8d8d8;
  padding: 14px 0 18px;

  a {
    color: black;
    text-decoration: none;
    transition: color 0.21s ease-out;

    &:hover,
    &:focus {
      ${({ color }) => `
        color: ${color};
      `}
    }
  }

  h3 {
    font-size: 22px;
    font-size: 1.375rem;
    margin-bottom: 0;

    @media screen and (min-width: 500px) {
      font-size: 30px;
      font-size: 1.875rem;
    }
  }

  h6 {
    color: #333;
    text-transform: uppercase;
    margin: 4px 0;

    @media screen and (max-width: 499px) {
      font-size: 16px;
      font-size: 1rem;
    }
  }

  p {
    font-size: 16px;
    font-size: 1rem;
    color: #555;
    margin-bottom: 3px;

    @media screen and (min-width: 500px) {
      font-size: 18px;
      font-size: 1.125rem;
    }
  }
`

const Events = (props) => {
  const context = useContext(SiteContext)
  const [view, setView] = useState('dayGridMonth')
  const [filters, setFilters] = useState({
    category: '',
    query: '',
  })
  const debouncedFilters = useDebounce(filters, 500)
  const [categories, setCategories] = useState([])
  const [selectedDay, setSelectedDay] = useState(DateTime.local())
  const [dayEvents, setDayEvents] = useState([])
  const [monthEvents, setMonthEvents] = useState([])
  const calendarRef = useRef(null)
  const { site, color } = props

  useEffect(() => {
    context.getEvents(site)

    const getCategories = new Promise((resolve) => {
      context.getEventCategories(resolve)
    })

    getCategories.then((data) => {
      setCategories(data)
    })
  }, [])

  useEffect(() => {
    highLightDays()

    if (context.events.length) {
      getDayEvents()
      getMonthEvents()
    }
  }, [context.events, selectedDay])

  useEffect(() => {
    setDayEvents([])

    context.getEvents(site, filters.category, filters.query)
  }, [debouncedFilters])

  const handleFilters = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    })
  }

  const getDayEvents = () => {
    const foundEvents = context.events.filter((event) => {
      return (
        DateTime.fromISO(event.start).toLocaleString(DateTime.DATE_SHORT) ===
        selectedDay.toLocaleString(DateTime.DATE_SHORT)
      )
    })

    setDayEvents(foundEvents)
  }

  const getMonthEvents = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      const calendarDate = DateTime.fromISO(calendarApi.getDate().toISOString())
      const month = calendarDate.month
      const year = calendarDate.year

      const foundEvents = context.events.filter((event) => {
        const eventDate = DateTime.fromISO(event.start)

        return month === eventDate.month && year === eventDate.year
      })

      setMonthEvents(foundEvents)
    }
  }

  const handleViewChange = (selectedView) => {
    setView(selectedView)

    const calendarApi = calendarRef.current.getApi()
    calendarApi.changeView(selectedView)
  }

  const handleDateClick = (arg) => {
    setSelectedDay(DateTime.fromISO(arg.dateStr))
  }

  const highLightDays = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current

      const days = calendarApi.elRef.current.querySelector('.fc-day-top')

      if (days) {
        Array.from(days).forEach((day) => day.classList.remove('active'))

        const selectedDate = selectedDay.toFormat('yyyy-MM-dd')
        const selected = calendarApi.elRef.current.querySelector(
          `.fc-day-top[data-date="${selectedDate}"]`
        )
        if (selected) {
          selected.classList.add('active')
        }
      }

      context.events.forEach((event) => {
        const eventDate = DateTime.fromISO(event.start).toFormat('yyyy-MM-dd')
        const dayNumber = calendarApi.elRef.current.querySelector(
          `.fc-day-top[data-date="${eventDate}"]`
        )

        if (dayNumber) {
          dayNumber.classList.add('has-event')
        }
      })
    }
  }

  return (
    <main>
      <ContentHero
        background={color}
        block={{
          settings: {
            heading: 'Calendar',
          },
        }}
      />

      <EventsContainer
        events={context.events}
        color={color}
        className={view === 'listMonth' ? 'list' : ''}
      >
        <EventFilters color={color}>
          <div>
            {categories.length > 0 && (
              <label className="dropdown">
                <span>Category</span>
                <select
                  name="category"
                  value={filters.category}
                  onChange={handleFilters}
                >
                  <option value="" />
                  {categories.map((category) => (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </label>
            )}

            <label>
              <span>Search</span>
              <input
                type="text"
                name="query"
                value={filters.query}
                onChange={handleFilters}
              />
            </label>
          </div>

          <div>
            <button
              className={view === 'dayGridMonth' ? 'active' : ''}
              onClick={() => handleViewChange('dayGridMonth')}
            >
              <CalendarIcon />
              Calendar View
            </button>

            <button
              className={view === 'listMonth' ? 'active' : ''}
              onClick={() => handleViewChange('listMonth')}
            >
              <ListIcon />
              List View
            </button>
          </div>
        </EventFilters>

        <Calendar fullWidth={view === 'listMonth'}>
          <FullCalendar
            defaultView="dayGridMonth"
            fixedWeekCount={false}
            showNonCurrentDates={false}
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            events={context.events}
            columnHeaderText={(date) => {
              return DateTime.fromISO(date.toISOString())
                .toFormat('ccccc')
                .toUpperCase()
            }}
            dateClick={handleDateClick}
            datesRender={() => {
              getMonthEvents()
            }}
            eventRender={() => {
              highLightDays()
            }}
            header={{
              left: 'prev',
              center: 'title',
              right: 'next',
            }}
            ref={calendarRef}
            className="fullcalendar"
          />

          {view === 'dayGridMonth' && (
            <Day color={color}>
              <h2>{selectedDay.toFormat('LL/dd')}</h2>
              <h4>{selectedDay.toFormat('cccc')}</h4>

              <ul>
                {dayEvents.map((event, index) => (
                  <Event key={index} color={color}>
                    <Link to={event.url}>
                      <h3>{event.title}</h3>
                    </Link>

                    {event.categories.length > 0 && (
                      <h6>
                        {event.categories.map(
                          (category, index) =>
                            `${index > 0 ? ', ' : ''}${category}`
                        )}
                      </h6>
                    )}
                    <p>{DateTime.fromISO(event.start).toFormat('h:mm a')}</p>
                    <p>{event.venue}</p>
                  </Event>
                ))}
              </ul>

              {dayEvents.length === 0 && <p>There are no events on this day</p>}
            </Day>
          )}

          {view === 'listMonth' && (
            <List>
              <ul>
                {monthEvents.map((event, index) => (
                  <Event key={index}>
                    <h3>{event.title}</h3>
                    {event.categories.length > 0 && (
                      <h6>
                        {event.categories.map(
                          (category, index) =>
                            `${index > 0 ? ', ' : ''}${category}`
                        )}
                      </h6>
                    )}
                    <p>{DateTime.fromISO(event.start).toFormat('h:mm a')}</p>
                    <p>{event.venue}</p>
                  </Event>
                ))}
              </ul>

              {monthEvents.length === 0 && (
                <p>There are no events this month</p>
              )}
            </List>
          )}
        </Calendar>
      </EventsContainer>
    </main>
  )
}

export default Events
