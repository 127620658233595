import styled from 'styled-components'
import LeftColumn from './LeftColumn'
import RightColumn from './RightColumn'

const Columns = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
  }
`

export { LeftColumn, RightColumn }

export default Columns
