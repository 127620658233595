import React, { useContext } from 'react'
import styled from 'styled-components'
import UnderlinedLink from '../UnderlinedLink'
import SiteContext from '../../state/context'

const RaceDatesContainer = styled.div`
  padding: 48px 0 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h2 {
    margin-bottom: 26px;
  }
`

const RaceDatesDetails = styled.div`
  box-shadow: -4px 8px 18px rgba(0, 0, 0, 0.25);
  padding: 30px 7.1vw 42px;
  margin-top: 18px;
  color: white;
  ${(props) => `
    background-color: ${props.background};
  `}

  @media screen and (min-width: 768px) {
    margin-left: -8.3vw;
  }
`

const Detail = styled.p`
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 700;
  color: black;
  margin: 0 0 11px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    font-size: 26px;
    font-size: 1.625rem;
  }

  label {
    color: white;
    text-transform: uppercase;
    font-size: 12px;
    font-size: 0.75rem;
    margin-right: 10px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      font-size: 1.25rem;
    }
  }

  a {
    font-size: 16px;
    font-size: 1rem;

    @media screen and (min-width: 768px) {
      font-size: 22px;
      font-size: 1.375rem;
    }
  }
`

const RaceDates = (props) => {
  const context = useContext(SiteContext)
  const { block, background } = props
  const { copy, course_map_link, date, details } = block.settings

  return (
    <RaceDatesContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: copy.replace(/(\/media)+/g, `${context.root}/media`),
        }}
      />

      <RaceDatesDetails background={background}>
        <h3>Details</h3>

        <Detail>
          <label>Date:</label>

          {date}
        </Detail>

        {(details || []).map((detail, index) => (
          <Detail key={index}>
            <label>{detail.label}</label>

            {detail.value}
          </Detail>
        ))}

        {(course_map_link || {}).url && (
          <UnderlinedLink
            href={course_map_link.url}
            target={course_map_link.new_tab ? '_blank' : ''}
          >
            Course Map
          </UnderlinedLink>
        )}
      </RaceDatesDetails>
    </RaceDatesContainer>
  )
}

export default RaceDates
