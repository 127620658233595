import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'
import ContentHero from '../../blocks/ContentHero'
import Columns, { LeftColumn, RightColumn } from '../../columns'
import SiteContext from '../../../state/context'
import Block from '../../Block'
import { displayDate, CardDate } from '../../blocks/NewsEvents'

const PostContainer = styled.section`
  margin-top: 58px;
  margin-bottom: 68px;

  div[class*='LeftColumn'] {
    h2:first-child {
      margin-bottom: 16px;
    }

    div[class*='CardDate'] + div[class*='Copy'] {
      margin-top: 44px;
    }
  }
`

const Icon = styled.div`
  padding: 16px 21px 0;

  @media screen and (min-width: 500px) {
    padding: 20px 8.3% 0;
  }

  @media screen and (min-width: 960px) {
    padding: 0 8.3%;
  }

  img {
    margin-left: -10px;
  }
`

const Post = (props) => {
  const context = useContext(SiteContext)
  const historyParams = useParams()
  const { colors, icon, type, site } = props
  const { primary, secondary, tertiary, button, darkText } = colors
  const { newsEventsBackgroundFallback } = props

  useEffect(() => {
    const preview = queryString.parse(location.search).preview

    context.getPost(
      historyParams.id,
      site || 'pittsburgh-marathon',
      type === 'news',
      preview
    )
  }, [])

  return (
    <main>
      <ContentHero
        background={secondary}
        block={{
          settings: {
            heading: type === 'blog' ? 'Blog' : 'News',
          },
        }}
      />

      {Object.keys(context.post).length > 0 && (
        <PostContainer>
          <Columns>
            <LeftColumn>
              <h2>{context.post.title}</h2>

              <CardDate>
                <span>
                  {context.post.published &&
                    displayDate(context.post.published)}
                </span>

                <span>
                  {(context.post.categories || []).map((category, index) => {
                    if (index > 0) {
                      return `, ${category}`
                    } else {
                      return category
                    }
                  })}
                </span>
              </CardDate>

              {context.post.blocks['Left Column'] &&
                context.post.blocks['Left Column'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </LeftColumn>

            <RightColumn>
              {context.post.blocks['Featured'] &&
                context.post.blocks['Featured'].map((block) => (
                  <Block
                    key={block.id}
                    data={block}
                    colors={{
                      primary,
                      secondary,
                      tertiary,
                      button,
                      darkText,
                    }}
                  />
                ))}
            </RightColumn>
          </Columns>

          {context.post.blocks['Above Footer'] &&
            context.post.blocks['Above Footer'].map((block) => (
              <Block
                key={block.id}
                data={block}
                colors={{
                  primary,
                  secondary,
                  tertiary,
                  button,
                  darkText,
                }}
              />
            ))}

          {icon && site === 'pittsburgh-marathon' && (
            <Icon>
              <img src={icon} />
            </Icon>
          )}
        </PostContainer>
      )}
    </main>
  )
}

export default Post
