import React from 'react'
import { ButtonLink } from '../Button'

const LinkBlock = (props) => {
  const { block, background, color, linkBlockBackground, linkColor } = props
  const { link, custom_link_text } = block.settings

  return (
    <React.Fragment>
      {(link || {}).url && (
        <ButtonLink
          href={link.url}
          color={linkColor || color}
          background={linkBlockBackground || background}
          style={{
            margin: '8px 0',
          }}
        >
          {custom_link_text || 'Register'}
        </ButtonLink>
      )}
    </React.Fragment>
  )
}

export default LinkBlock
