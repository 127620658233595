import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'

const CopyContainer = styled.div`
  margin-top: 52px;
  padding-bottom: 20px;

  &:last-child {
    margin-bottom: 62px;

    @media screen and (min-width: 600px) {
      margin-bottom: 80px;
    }
  }
`

const Copy = styled.div`
  & ul,
  & ol {
    padding-inline-start: 20px;

    & li {
      margin-bottom: 24px;
      padding-left: 10px;

      @media screen and (min-width: 768px) {
        margin-bottom: 34px;
      }
    }
  }

  & ol {
    padding-inline-start: 18px;
  }

  a {
    ${({ links }) => `
      color: ${links};
      transition: opacity 0.21s ease-out;

      &:hover,
      &:focus {
        opacity: 0.65;
      }
    `}
  }

  .wysiwyg-link {
    display: inline-block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    padding-bottom: 4px;
    margin-top: 16px;
    transition: padding-right 0.21s ease-out;

    ${(props) => `
      color: ${props.primary};
      border-bottom: 4px solid ${props.secondary};
    `}

    @media screen and (min-width: 500px) {
      font-size: 22px;
      font-size: 1.375rem;
      margin-top: 32px;
    }

    &:hover,
    &:focus {
      padding-right: 20px;
    }
  }
`

const ImageContainer = styled.img`
  max-width: 100%;
`

const Wysiwyg = (props) => {
  const context = useContext(SiteContext)
  const { block, links, primary, secondary } = props
  const { copy } = block.settings
  const { image } = block.settings

  return (
    <CopyContainer>
      <Copy
        links={links}
        primary={primary}
        secondary={secondary}
        dangerouslySetInnerHTML={{
          __html: copy.replace(/(\/media)+/g, `${context.root}/media`),
        }}
      />
      <React.Fragment>
        {image && (
          <ImageContainer
            src={`${context.root}${image.image}`}
            // alt={alt_attribute_description}
          />
        )}
      </React.Fragment>
    </CopyContainer>
  )
}

export default Wysiwyg
