import React, { useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as P3RLogo } from '../../images/P3R_logo_full.svg'
import UnderlinedLink from '../UnderlinedLink'
import SiteContext from '../../state/context'

const ContactFormContainer = styled.section`
  padding: 40px 21px 76px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 500px) {
    padding: 40px 8.3% 76px;
  }

  @media screen and (min-width: 960px) {
    padding: 40px 0 76px 8.3%;
    display: grid;
    grid-template-columns: 1fr 400px;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 547px;
  }

  p {
    order: 2;
    grid-column: 1 / 3;
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 600;
  }
`

const FormContainer = styled.div`
  order: 3;
  padding: 20px 21px 0 0;
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  @media screen and (min-width: 500px) {
    padding: 42px 8.3vw 0 0;
  }

  form {
    max-width: none;
  }

  button[type='submit'] {
    color: white;

    ${({ primary }) => `
      background-color: ${primary};
    `}
  }

  .form-group--half,
  .email-field,
  .phone-field {
    @media screen and (max-width: 649px) {
      width: 100%;
      max-width: 100%;
    }
  }
`

const ContactInfo = styled.div`
  width: 100%;
  padding: 28px 20px;
  color: white;
  box-shadow: -4px 8px 18px rgba(0, 0, 0, 0.25);
  display: flex;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  margin-bottom: 42px;

  @media screen and (min-width: 500px) {
    padding: 32px 52px 50px;
    margin-bottom: 52px;
  }

  @media screen and (min-width: 960px) {
    margin-bottom: 0;
  }

  ${({ background }) => `
    background-color: ${background};
  `}

  svg {
    margin-bottom: 18px;
  }

  p,
  ul,
  ol {
    color: inherit;
  }

  p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;

    @media screen and (min-width: 500px) {
      font-size: 22px;
      font-size: 1.375rem;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  > div *:last-child {
    margin-bottom: 0;
  }
`

const ContactForm = (props) => {
  const context = useContext(SiteContext)
  const { block, primary, tertiary, linkBlockBackground } = props
  const { form } = block
  const { content, links, title } = block.settings

  const formRef = useRef(null)

  useEffect(() => {
    const formSubmit = (event) => {
      event.preventDefault()

      const form = formRef.current.querySelector('form')
      const data = new FormData(form)

      const req = new XMLHttpRequest()
      req.onreadystatechange = (event) => {
        if (event.target.response) {
          const response = JSON.parse(event.target.response)
          formRef.current.innerHTML = response.payload
        }
      }
      req.open('POST', form.getAttribute('action'))
      req.send(data)
    }

    if (formRef.current) {
      const form = formRef.current.querySelector('form')

      if (form) {
        form.setAttribute(
          'action',
          `${context.root}${form.getAttribute('action')}`
        )
        form.addEventListener('submit', formSubmit)
      }
    }

    return () => {
      formRef.current
        .querySelector('form')
        .removeEventListener('submit', formSubmit)
    }
  }, [formRef])

  return (
    <ContactFormContainer>
      {title && <p>{title}</p>}

      <ContactInfo background={tertiary}>
        <P3RLogo />

        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(/(\/media)+/g, `${context.root}/media`),
          }}
        />

        {links.length > 0 && (
          <React.Fragment>
            {links.map((link, index) => (
              <UnderlinedLink
                key={index}
                href={(link.link.data || {}).url}
                target={(link.link.data || {}).new_tab ? '_blank' : ''}
              >
                {link.content}
              </UnderlinedLink>
            ))}
          </React.Fragment>
        )}
      </ContactInfo>

      <FormContainer primary={linkBlockBackground || primary}>
        {form && (
          <div
            ref={formRef}
            dangerouslySetInnerHTML={{
              __html: form,
            }}
          />
        )}
      </FormContainer>
    </ContactFormContainer>
  )
}

export { ContactFormContainer, FormContainer }

export default ContactForm
