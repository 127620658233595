import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'
import UnderlinedLink from '../UnderlinedLink'

const RacePromoContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 46px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin: 46px 0 72px;
  }
`

const RacePromoYear = styled.div`
  flex: 0 0 auto;
  padding-top: 13px;
  position: relative;
  order: 2;

  @media screen and (min-width: 768px) {
    order: 1;
    padding-top: 0px;
    width: 45%;
  }

  h3 {
    color: #d8eafe;
    font-size: 44vw;
    font-weight: 800;
    line-height: 1;
    letter-spacing: -0.03em;
    margin-left: -2.5vw;
    position: relative;
    text-align: center;
    z-index: 2;

    @media screen and (min-width: 768px) {
      font-size: 22vw;
      text-align: left;
    }
  }

  img {
    position: absolute;
    object-fit: contain;
    object-position: top left;
    z-index: 3;

    &:nth-of-type(1) {
      height: 85px;
      top: 6px;
      left: -21px;

      @media screen and (min-width: 768px) {
        width: 160px;
        height: 132px;
        top: -24px;
        left: 68px;
      }

      @media screen and (min-width: 1200px) {
        height: 180px;
      }
    }

    &:nth-of-type(2) {
      height: 110px;
      bottom: -26px;
      left: 25%;

      @media screen and (min-width: 768px) {
        width: 160px;
        height: 185px;
        bottom: -72px;
        left: 40%;
      }

      @media screen and (min-width: 1200px) {
        height: 260px;
      }
    }

    &:nth-of-type(3) {
      width: 144px;
      top: 106px;
      right: -21px;

      @media screen and (min-width: 768px) {
        width: 207px;
        height: 160px;
        top: -66px;
        right: -80px;
        z-index: 1;
      }

      @media screen and (min-width: 1200px) {
        width: 270px;
      }
    }
  }
`

const RacePromoText = styled.div`
  color: white;
  flex: 0 0 auto;
  padding: 26px 8.3% 23px;
  position: relative;
  order: 1;
  z-index: 2;
  ${(props) => `
    background-color: ${props.background};
  `}

  @media screen and (min-width: 768px) {
    padding: 40px 8.3% 48px 24px;
    order: 2;
    width: 55%;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: skew(-30.5deg);
      transform-origin: top right;
      ${(props) => `
        background-color: ${props.background};
      `}
    }
  }

  & > div {
    position: relative;

    > a {
      margin-top: 8px;
    }
  }

  & h2 {
    color: white;
    font-size: 34px;
    font-size: 2.125rem;
    margin-bottom: 26px;
  }

  & p {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      font-size: 26px;
      font-size: 1.625rem;
    }
  }
`

const RacePromo = (props) => {
  const context = useContext(SiteContext)
  const { block, background } = props
  const {
    heading,
    copy,
    image_cutout_1,
    image_cutout_2,
    image_cutout_3,
    link,
    link_text,
    year,
  } = block.settings

  return (
    <RacePromoContainer>
      <RacePromoYear>
        {year && <h3>{year}</h3>}

        {(image_cutout_1 || {}).image && (
          <img src={`${context.root}${image_cutout_1.image}`} />
        )}

        {(image_cutout_2 || {}).image && (
          <img src={`${context.root}${image_cutout_2.image}`} />
        )}

        {(image_cutout_3 || {}).image && (
          <img src={`${context.root}${image_cutout_3.image}`} />
        )}
      </RacePromoYear>

      <RacePromoText background={background}>
        <div>
          <h2>{heading}</h2>

          {copy && (
            <div
              dangerouslySetInnerHTML={{
                __html: copy.replace(/(\/media)+/g, `${context.root}/media`),
              }}
            />
          )}

          {(link || {}).url && (
            <UnderlinedLink
              href={link.url}
              target={link.new_tab ? '_blank' : ''}
            >
              {link_text || 'Learn More'}
            </UnderlinedLink>
          )}
        </div>
      </RacePromoText>
    </RacePromoContainer>
  )
}

export default RacePromo
