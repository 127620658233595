import styled from 'styled-components'

const RightColumn = styled.div`
  padding: 0 21px;
  overflow: hidden;

  @media screen and (min-width: 500px) {
    padding: 0 8.3%;
  }

  @media screen and (min-width: 768px) {
    padding: 0;
    width: 34%;
    flex: 0 0 auto;

    > img[class*='ImageContainer'] {
      max-width: calc(100% + 8.3vw);
    }
  }
`

export default RightColumn
