import React, { useContext } from 'react'
import styled from 'styled-components'
import SiteContext from '../../state/context'

const ImageContainer = styled.img`
  max-width: 100%;
`

const Image = (props) => {
  const context = useContext(SiteContext)
  const { block } = props
  const { image, alt_attribute_description } = block.settings

  return (
    <React.Fragment>
      {image && (
        <ImageContainer
          src={`${context.root}${image.image}`}
          alt={alt_attribute_description}
        />
      )}
    </React.Fragment>
  )
}

export default Image
