import React, { useContext } from 'react'
import styled from 'styled-components'
import { ButtonLink } from '../Button'
import SiteContext from '../../state/context'

const Promo = styled.section`
  padding: 28px 0 32px;
  display: flex;
  flex-direction: column-reverse;

  ${(props) => `
    background-color: ${props.background};
  `}

  @media screen and (min-width: 800px) {
    flex-direction: row;
    align-items: center;
    padding: 28px 0 54px;
  }

  & div {
    &:first-child {
      width: calc(100% - 16px);
      flex: 0 0 auto;
      position: relative;

      @media screen and (min-width: 800px) {
        width: 43.3%;
      }
    }

    &:last-child {
      padding: 0 21px;
      margin-bottom: 36px;

      @media screen and (min-width: 500px) {
        padding: 0 8.3%;
      }

      @media screen and (min-width: 800px) {
        padding-left: 60px;
        margin-bottom: 0;
      }
    }
  }

  & img {
    width: 100%;
  }

  & svg {
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
  }

  & h3 {
    margin-bottom: 20px;
  }

  & p {
    font-weight: 600;
    font-size: 18px;
    font-size: 1.125rem;

    @media screen and (min-width: 500px) {
      font-size: 22px;
      font-size: 1.375rem;
    }
  }

  & a {
    margin-top: 13px;
  }
`

const DefaultPromo = (props) => {
  const context = useContext(SiteContext)
  const { settings } = props.block
  const { colors } = props
  return (
    <Promo background={colors.promoBannersBackground || colors.primary}>
      <div>
        {settings.image && (
          <React.Fragment>
            <img
              src={`${context.root}${settings.image.image}`}
              alt={settings.image_alt}
            />

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 370">
              <polygon
                points="220 0 220 370 0 370 220 0"
                fill={colors.promoBannersBackground || colors.primary}
              />
            </svg>
          </React.Fragment>
        )}
      </div>

      <div>
        {settings.heading && (
          <h3
            style={colors.primaryText ? { color: 'white' } : { color: 'black' }}
          >
            {settings.heading}
          </h3>
        )}

        {settings.copy && (
          <div
            style={colors.primaryText ? { color: 'white' } : { color: 'black' }}
            dangerouslySetInnerHTML={{
              __html: settings.copy.replace(
                /(\/media)+/g,
                `${context.root}/media`
              ),
            }}
          />
        )}

        {(settings.link || {}).url && (
          <ButtonLink
            href={settings.link.url}
            target={settings.link.new_tab ? '_blank' : ''}
            color={colors.primaryText ? 'black' : 'white'}
            background={colors.button}
          >
            {settings.link_text || 'Learn More'}
          </ButtonLink>
        )}
      </div>
    </Promo>
  )
}

export default DefaultPromo
