import styled from 'styled-components'

const LeftColumn = styled.div`
  padding: 0 21px;

  @media screen and (min-width: 500px) {
    padding: 0 8.3%;
  }

  @media screen and (min-width: 768px) {
    width: 66%;
    flex: 0 0 auto;
  }

  > *[class*='Copy'] {
    margin-bottom: 0;
  }
`

export default LeftColumn
