import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SiteContext from '../state/context'
import SVGInject from '@iconfu/svg-inject'
import { yellow, blue, red, button } from '../colors'
import { Collapse } from '@material-ui/core'
import arrowDown from '../images/arrow_down.svg'
import { ReactComponent as SearchIcon } from '../images/icon_search.svg'
import hoverintent from 'hoverintent'

const HeaderContainer = styled.header`
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 5;
`

const HeaderInner = styled.div`
  background-color: ${(props) => props.background};
  padding: 25px 21px 52px;
  position: relative;
  min-height: 45px;

  @media screen and (min-width: 600px) {
    padding: 65px 20px 20px;
  }

  @media screen and (min-width: 960px) {
    padding-left: 47px;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px 0 242px;
    min-height: 100px;
  }

  @media screen and (min-width: 1120px) {
    padding-left: 284px;
    min-height: unset;
  }

  > nav,
  > img {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  > div[class*='MuiCollapse'] {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
`

const HeaderLogo = styled(Link)`
  display: block;
  position: absolute;
  top: -17px;
  left: 33px;

  @media screen and (min-width: 600px) {
    top: -42px;
    left: 52px;
    padding: 5px;
  }

  @media screen and (min-width: 960px) {
    left: 82px;
  }

  @media screen and (min-width: 1024px) {
    top: -28px;
  }

  @media screen and (min-width: 1120px) {
    top: -40px;
    left: 84px;
  }

  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;

  &::before {
    display: block;
    width: 100px;
    height: 81px;
    position: absolute;
    content: '';
    background: white;
    left: -5px;
    transform: skew(-13.5deg);
    box-shadow: 0 25px 48px rgba(0, 0, 0, 0.2);

    @media screen and (min-width: 600px) {
      width: 151px;
      height: 120px;
    }

    @media screen and (min-width: 1120px) {
      width: 175px;
      height: 142px;
    }
  }

  & img {
    width: 65px;
    height: 75px;
    left: 12px;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 600px) {
      ${(props) => `
        width: ${props.mwidth};
        height: ${props.mheight};
        left: ${props.mleft};
      `}
    }

    @media screen and (min-width: 1120px) {
      ${(props) => `
        width: ${props.lwidth};
        left: ${props.lleft};
      `}

      height: 130px;
    }
  }

  & div {
    width: 85px;
    height: 42px;
    background-color: ${yellow};
    position: absolute;
    top: 5px;
    left: 38px;
    transform: skew(-30.5deg);
    display: flex;
    align-items: center;
    padding-left: 12px;

    @media screen and (min-width: 600px) {
      width: 211px;
      height: 97px;
      top: 11px;
      left: 86px;
      padding-left: 34px;
    }

    @media screen and (min-width: 1120px) {
      width: 237px;
      height: 116px;
      top: 13px;
      left: 105px;
    }

    & span {
      transform: skew(30.5deg);
      font-size: 28px;
      font-size: 1.75rem;
      font-weight: 800;
      position: relative;
      top: -3px;
      color: black;

      @media screen and (min-width: 600px) {
        font-size: 66px;
        font-size: 4.125rem;
        top: -5px;
      }

      @media screen and (min-width: 1120px) {
        font-size: 78px;
        font-size: 4.875rem;
      }
    }
  }
`

const MainMenu = styled.nav`
  > ul > li {
    @media screen and (min-width: 1024px) {
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }

  > ul > li > a {
    width: calc(100% - 24px);
    display: inline-block;
    color: ${(props) => props.primaryText || 'black'};

    @media screen and (min-width: 600px) {
      width: 300px;
    }

    @media screen and (min-width: 1024px) {
      width: auto;
      padding: 43px 16px;
    }
  }

  & > ul > li.button {
    + .button > a {
      background: transparent;
      position: relative;
      position: relative;
      border-radius: 0;
      padding: 5px 0;
      top: 5px;
      border-bottom: 4px solid ${(props) => props.registerBackground || button};

      &:hover {
        background: transparent;
        color: ${button};

        &:after {
          width: calc(100% - 8px);
        }
      }
    }

    > a {
      color: white;
      display: none;
      font-size: 22px;
      font-size: 1.375rem;
      font-weight: 800;
      font-family: inherit;
      text-transform: uppercase;
      text-decoration: none;
      padding: 10px 40px 12px;
      border-radius: 4px;
      transition: background-color 0.21s ease-out;
      margin: 20px 16px 16px;
      width: auto;
      background-color: ${(props) => props.registerBackground || button};

      @media screen and (min-width: 1024px) {
        margin: 0 7px 0;
        padding: 10px 14px 10px;
        display: inline-block;
        font-size: 16px;
        font-size: 1rem;
      }

      @media screen and (min-width: 1250px) {
        margin: -2px 1rem 0;
        padding: 10px 29px 10px;
        font-size: 20px;
        font-size: 1.25rem;
      }

      &:hover,
      &:focus {
        background-color: #555;
      }
    }
  }

  & ul {
    margin: 0;
    padding: 58px 0 0;
    list-style: none;

    & > .has-children {
      &:hover {
        & > a {
          color: black;
        }
      }
    }

    @media screen and (min-width: 600px) {
      padding: 30px 0 0;
    }

    @media screen and (min-width: 1024px) {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  & a {
    display: block;
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800;
    text-decoration: none;
    color: black;
    padding: 0 16px 8px 0;
    transition: color 0.21s ease-out;

    @media screen and (min-width: 600px) {
      font-size: 32px;
      font-size: 2rem;
      padding: 0 16px;
    }

    @media screen and (min-width: 1024px) {
      padding: 6px 16px 0;
      font-size: 20px;
      font-size: 1.25rem;
      position: relative;
    }

    @media screen and (min-width: 1250px) {
      font-size: 24px;
      font-size: 1.5rem;
    }

    &:hover,
    &:focus {
      color: ${(props) => props.menuHover};
    }

    + button {
      appearance: none;
      border: 0;
      padding: 0;
      background: none;
      transition: opacity 0.21s ease-out;
      width: 24px;
      height: 24px;
      padding: 4px;
      display: inline-block;
      justify-content: center;
      align-items: center;
      position: relative;

      @media screen and (min-width: 600px) {
        width: 32px;
        height: 32px;
        padding: 4px;
      }

      @media screen and (min-width: 1024px) {
        display: none;
      }

      &:hover,
      &:focus {
        opacity: 0.65;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: url('${arrowDown}') center no-repeat;
        background-size: contain;
      }
    }
  }

  & ul ul {
    width: calc(100% + 32px);
    flex-wrap: wrap;
    display: none;
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
    margin: 0 -16px;
    padding: 0 16px;
    background-color: ${(props) => props.background || 'white'};

    @media screen and (min-width: 600px) {
      width: calc(100% + 20px);
      margin: 0 -20px;
      padding: 0 20px 0 36px;
    }

    @media screen and (min-width: 1024px) {
      width: 100%;
      gap: 24px;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      position: absolute;
      top: 100%;
      left: -99999px;
      display: flex !important;
      margin: 0;
      padding: 62px 33px 62px 48px;
      box-shadow: 0 14px 16px rgba(0, 0, 0, 0.2);
      border-top: 4px solid
        ${(props) => props.navHoverBorderBottom || `${blue}`};
      transition: opacity 0.21s ease-out, left 0s 0.21s;
      opacity: 0;

      &:not(.has-promo) {
        height: auto !important;
      }

      &.show {
        left: 0;
        opacity: 1;
        transition: opacity 0.21s ease-out;
      }
    }

    li {
      width: 50%;
      flex: 0 0 auto;
      margin-bottom: 12px;

      @media screen and (min-width: 600px) {
        width: auto;
        margin-bottom: 32px;
      }

      &:not(.race-weekend):first-child {
        padding-top: 10px;

        @media screen and (min-width: 1024px) {
          padding-top: 0;
        }
      }

      &.race {
        @media screen and (min-width: 600px) {
          width: 14.285%;
          min-width: 172px;
        }
      }

      &:not(.race) {
        margin-bottom: 10px;
        align-self: flex-start;

        @media screen and (max-width: 1023px) {
          &:not(.race-weekend) {
            width: 100%;
          }
        }

        @media screen and (min-width: 1024px) {
          margin-bottom: 16px;
        }
      }

      a {
        display: block;
        padding-left: 0;
        padding-right: 32px;
        font-size: 16px;
        font-size: 1rem;

        @media screen and (min-width: 600px) {
          font-size: 18px;
          font-size: 1.25rem;
          padding-right: 40px;
        }

        @media screen and (min-width: 1024px) {
          font-size: 26px;
          font-size: 1.625rem;
          padding-right: 35px;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0.8;
          }
        }

        &:hover,
        &:focus {
          .badge {
            background-color: black;

            svg * {
              fill: ${yellow};
            }
          }
        }
      }
    }

    &.has-promo {
      @media screen and (min-width: 1024px) {
        padding-left: 524px;
      }
    }

    .race-weekend {
      width: calc(100% - 36px);
      flex: 0 0 auto;
      order: 0;
      margin-right: auto;
      background-color: ${red};
      margin-left: -36px;
      padding: 14px 0 14px 36px;
      position: relative;

      @media screen and (min-width: 600px) {
        width: 500px;
      }

      @media screen and (min-width: 1024px) {
        width: 328px;
        padding: 62px 0 62px 48px;
        position: absolute;
        top: 0;
        left: 36px;
      }

      &::before {
        content: '';
        background-color: ${red};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform: skew(-30.5deg);
        transform-origin: bottom left;
      }

      > * {
        position: relative;
        margin-right: -16px;
      }

      a {
        color: white;
        font-size: 32px;
        font-size: 2rem;
        transition: opacity 0.21s ease-out;
        padding-right: 0;

        @media screen and (max-width: 599px) {
          font-size: 24px;
          font-size: 1.5rem;
        }

        + button {
          display: none;
        }

        &:hover,
        &:focus {
          opacity: 0.7;
        }
      }

      ul {
        top: 0;
        left: 0;
        border-top: 0;
        padding: 0;
        background: none;
        display: block;
        box-shadow: none;
        margin-top: 10px;
        display: block !important;
        height: auto !important;
        margin-left: 0;

        li {
          width: 100%;
          margin-bottom: 10px;
        }

        a {
          font-size: 16px;
          font-size: 1rem;

          @media screen and (min-width: 600px) {
            font-size: 20px;
            font-size: 1.25rem;
          }

          @media screen and (min-width: 1024px) {
            font-size: 26px;
            font-size: 1.625rem;
          }
        }
      }
    }
  }

  .badge {
    position: relative;
    background-color: ${yellow};
    transform: skew(-30.5deg);
    transform-origin: bottom left;
    overflow: hidden;
    transition: background-color 0.21s ease-out;
    margin-bottom: 6px;

    @media screen and (min-width: 600px) {
      margin-bottom: 18px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 49.3%;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: skew(30.5deg);
      object-fit: cover;
      overflow: visible;

      * {
        transition: fill 0.21s ease-out;
      }
    }
  }

  // todo
  > ul > li.has-children {
    padding: 0;
    @media screen and (min-width: 1024px) {
      a {
        padding: 43px 16px;
        &:hover {
          color: white;
        }
      }

      &:hover {
        background: ${(props) => props.registerBackground || button};

        a {
          color: white;
        }

        > ul {
          background: ${(props) => props.registerBackground || button};

          * {
            color: white;
          }
        }
      }
    }
  }
`

const SubMenu = styled.nav`
  margin-top: 17px;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    ul {
      display: none;
    }
  }

  & a {
    display: block;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    text-decoration: none;
    color: black;
    padding: 0 16px 11px 0;
    transition: color 0.21s ease-out;
    text-transform: uppercase;

    @media screen and (min-width: 600px) {
      font-size: 20px;
      font-size: 1.25rem;
      padding: 0 16px 11px;
    }

    &:hover,
    &:focus {
      color: ${blue};
    }

    + button {
      display: none;
    }
  }

  & > ul > li.button > a {
    color: white;
    display: inline-block;
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 800;
    font-family: inherit;
    text-transform: uppercase;
    text-decoration: none;
    padding: 10px 40px 12px;
    border-radius: 4px;
    transition: background-color 0.21s ease-out;
    margin: 20px 16px 16px 0px;
    width: auto;

    ${(props) => `
      background-color: ${props.registerBackground || button};
    `}

    @media screen and (min-width: 600px) {
      margin-left: 13px;
    }

    @media screen and (min-width: 1024px) {
      margin: -4px 16px 0;
      padding: 8px 18px 10px;
    }

    @media screen and (min-width: 1250px) {
      margin-top: -2px;
      padding: 10px 40px 12px;
    }

    &:hover,
    &:focus {
      background-color: #555;
    }
  }

  & > ul > li.button:first-of-type {
    display: none;
  }
`

const MenuToggle = styled.button`
  position: absolute;
  top: 8px;
  right: 16px;
  width: 30px;
  height: 30px;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: none;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  @media screen and (min-width: 600px) {
    top: 27px;
    right: 28px;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  &:hover,
  &:focus {
    opacity: 0.65;
  }

  span {
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background-color: black;
    position: absolute;
    left: 0;

    &:nth-child(1) {
      top: 5px;
      transition: top 0.2s 0.2s ease-in-out, transform 0.2s ease-in-out;
    }

    &:nth-child(2) {
      top: 13px;
      transition: opacity 0s 0.2s;
    }

    &:nth-child(3) {
      top: 21px;
      transition: top 0.2s 0.2s ease-in-out, transform 0.2s ease-in-out;
    }
  }

  &.open {
    span {
      &:nth-child(1) {
        top: 13px;
        transform: rotate(45deg);
        transition: top 0.2s ease-in-out, transform 0.2s 0.2s ease-in-out;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 13px;
        transform: rotate(-45deg);
        transition: top 0.2s ease-in-out, transform 0.2s 0.2s ease-in-out;
      }
    }
  }
`

const Search = styled.div`
  padding: 10px 16px 0;
  height: 54px;
  position: relative;

  @media screen and (min-width: 600px) {
    padding: 10px 16px 0;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }

  > button {
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    top: calc(50% + 10px);
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;

    @media screen and (min-width: 600px) {
      left: 16px;
    }

    svg {
      width: 30px;
      height: 26px;

      path {
        fill: black;
        transition: fill 0.21s ease-out;
      }
    }

    &:hover,
    &:focus {
      svg path {
        ${(props) => `
          fill: ${props.hover}
        `}
      }
    }
  }

  form {
    overflow: hidden;
    transition: width 0.3s ease-out;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;

    input,
    button {
      height: 44px;
    }

    input {
      background: none;
      border: 2px solid #dfdfdf;
    }

    button {
      padding: 0 14px 2px;
      margin-left: -5px;
      ${(props) => `
        background-color: ${props.searchBackground || yellow};
        color: ${props.primaryText || 'black'};
      `}
    }
  }
`

const DefaultHeader = (props) => {
  console.log(props.menus)

  const context = useContext(SiteContext)
  const [open, setOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const { menus } = props
  const { searchBackground, primaryText } = props
  const { mWidth, mHeight, mLeft } = props.mediumLogoProperties
  const { lWidth, lLeft } = props.largeLogoProperties

  useEffect(() => {
    if (props.site === 'eqt-pittsburgh-10-miler') {
      context.getSite('eqt-pittsburgh-10-miler')
    } else if (props.site === 'fleet-feet-liberty-mile') {
      context.getSite('fleet-feet-liberty-mile')
    }

    if (document.querySelectorAll('img.injectable').length) {
      SVGInject(document.querySelectorAll('img.injectable'), {
        afterLoad: (svg) => {
          if (svg) {
            svg.setAttribute('preserveAspectRatio', 'xMidYMid slice')
          }
        },
      })
    }

    const topLevel = document.querySelectorAll('.primary-nav > li > a')

    Array.from(topLevel).forEach((link) => {
      hoverintent(
        link,
        () => {
          hideSubNavs()
          const childNav = Array.from(link.parentNode.children).find(
            (el) => el.tagName === 'UL'
          )
          if (childNav) {
            childNav.classList.add('show')
          }
        },
        () => {}
      )
    })

    document.querySelector('[class*="SubHeader"]').onmouseover = () => {
      hideSubNavs()
    }

    const registerNavButtons = document.querySelectorAll(
      '[class*="SubHeader"] nav .button'
    )

    registerNavButtons.forEach((button) => {
      button.style.display = 'none'
    })

    const outsideElements = document.querySelectorAll('main, footer')
    Array.from(outsideElements).forEach((el) => {
      el.onmouseover = () => {
        hideSubNavs()
      }
    })

    const childrenToggles = document.querySelectorAll('.primary-nav a + button')
    Array.from(childrenToggles).forEach((button) => {
      button.addEventListener('click', showChildNav)
    })

    const raceWeekend = document.querySelector('.race-weekend')

    if (raceWeekend) {
      const raceWeekendParent = raceWeekend.parentElement
      raceWeekendParent.classList.add('has-promo')

      document.addEventListener(
        'ready',
        setRaceWeekendHeight(raceWeekendParent, raceWeekend)
      )
      document.addEventListener(
        'resize',
        setRaceWeekendHeight(raceWeekendParent, raceWeekend)
      )
    }

    return () => {
      Array.from(childrenToggles).forEach((button) => {
        button.removeEventListener('click', showChildNav)
      })

      if (raceWeekend) {
        document.removeEventListener('ready', setRaceWeekendHeight)
        document.removeEventListener('resize', setRaceWeekendHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showChildNav = (event) => {
    const target = event.currentTarget
    slideToggle(target.nextSibling)
  }

  const setRaceWeekendHeight = (parent, promo) => {
    if (parent) {
      parent.style.height = 'auto'

      const defaultHeight = parent.offsetHeight
      const promoHeight = promo.offsetHeight

      const newHeight =
        defaultHeight > promoHeight ? defaultHeight : promoHeight
      parent.style.height = `${newHeight}px`
    }
  }

  const slideToggle = (target) => {
    if (target.offsetHeight === 0) {
      target.style.display = 'flex'
      target.style.height = `${target.scrollHeight}px`
      target.previousSibling.style.transform = 'rotate(180deg)'
    } else {
      target.style.height = 0
      target.previousSibling.style.transform = 'rotate(0deg)'

      setTimeout(() => {
        target.style.display = 'none'
      }, 400)
    }
  }

  const hideSubNavs = () => {
    const allChildNavs = document.querySelectorAll('header li.has-children ul')
    Array.from(allChildNavs).forEach((el) => el.classList.remove('show'))
  }

  return (
    <HeaderContainer>
      <HeaderInner background={props.background}>
        <HeaderLogo
          to="/"
          mwidth={mWidth}
          mheight={mHeight}
          mleft={mLeft}
          lwidth={lWidth}
          lleft={lLeft}
        >
          {context.site && (
            <img
              src={`${context.root}${context.site.logo}`}
              alt={props.logoAlt}
            />
          )}
        </HeaderLogo>

        <MenuToggle
          onClick={() => setOpen(!open)}
          className={open ? 'open' : ''}
        >
          <span></span>
          <span></span>
          <span></span>
        </MenuToggle>

        {(menus || {}).main && (
          <React.Fragment>
            <MainMenu
              menuHover={props.menuHover}
              navHoverBorderBottom={props.navHoverBorderBottom}
              registerBackground={props.registerBackground}
              background={props.background}
              primaryText={props.primaryText}
              dangerouslySetInnerHTML={{
                __html: menus.main,
              }}
            />

            <Collapse in={open} className="mobile-menu">
              <MainMenu
                background={props.background}
                dangerouslySetInnerHTML={{
                  __html: menus.main,
                }}
              />

              <SubMenu
                registerBackground={props.registerBackground}
                dangerouslySetInnerHTML={{
                  __html: menus.sub,
                }}
              />

              <Search
                button={yellow}
                hover={blue}
                searchBackground={searchBackground}
                primaryText={primaryText}
              >
                <button
                  onClick={() => setShowSearch(true)}
                  style={{
                    opacity: showSearch ? 0 : 1,
                    pointerEvents: showSearch ? 'none' : 'auto',
                  }}
                >
                  <SearchIcon />
                </button>

                <form
                  action="/search"
                  method="get"
                  style={{
                    width: showSearch ? '100%' : '0%',
                  }}
                >
                  <input type="text" name="query" />

                  <button>Search</button>
                </form>
              </Search>
            </Collapse>
          </React.Fragment>
        )}
      </HeaderInner>
    </HeaderContainer>
  )
}

export default DefaultHeader
