import React, { useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import SiteContext from './context'
import {
  siteReducer,
  SET_SITE,
  SET_PAGE,
  SET_RACE,
  SET_EVENTS,
  SET_EVENT,
  SET_FEATURED_NEWS,
  SET_NEWS,
  APPEND_NEWS,
  SET_TOTAL,
  SET_POST,
  SET_RESULTS,
} from './reducer'

const SiteProvider = (props) => {
  const history = useHistory()
  const [state, dispatch] = useReducer(siteReducer, {
    site: {},
    page: {},
    race: {},
    events: [],
    event: {},
    featuredNews: [],
    news: [],
    total: 0,
    post: {},
    results: [],
    root: props.root,
  })

  const getSite = (slug) => {
    fetch(`${state.root}/sites/get_site.json?site=${slug}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: SET_SITE,
          site: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getHomepage = (site, template, preview) => {
    fetch(
      `${state.root}/sites/homepage.json?site=${site}&template=${template}&preview=${preview}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.page) {
          dispatch({
            type: SET_PAGE,
            page: data.page,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getPage = (id, site, preview) => {
    fetch(
      `${state.root}/sites/page.json?slug=${id}&site=${site}&preview=${preview}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.page) {
          dispatch({
            type: SET_PAGE,
            page: data.page,
          })
        } else {
          history.push('/404')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getRace = (id, site) => {
    fetch(`${state.root}/sites/race.json?slug=${id}&site=${site}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.race) {
          dispatch({
            type: SET_RACE,
            race: data.race,
          })
        } else {
          history.push('/404')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEvents = (site, category, query) => {
    fetch(
      `${state.root}/sites/events.json?site=${site}&category=${category}&query=${query}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          dispatch({
            type: SET_EVENTS,
            events: data,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEventCategories = (resolve) => {
    fetch(`${state.root}/sites/event_categories.json`)
      .then((response) => response.json())
      .then((categories) => resolve(categories))
      .catch((error) => {
        console.error(error)
        resolve([])
      })
  }

  const getEvent = (id, site, preview) => {
    fetch(
      `${state.root}/sites/event.json?slug=${id}&site=${site}&preview=${preview}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.event) {
          dispatch({
            type: SET_EVENT,
            event: {
              ...data.event,
              blocks: data.event.blocks ? data.event.blocks : {},
            },
          })
        } else {
          history.push('/404')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getFeaturedNews = (site, news) => {
    fetch(`${state.root}/sites/featured_news.json?site=${site}&news=${news}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: SET_FEATURED_NEWS,
          featuredNews: data.news,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getNews = (site, news, category, page) => {
    let path = `${state.root}/sites/news.json?site=${site}&news=${news}`

    if (category) {
      path += `&category=${category}`
    }

    if (page) {
      path += `&page=${page}`
    }

    fetch(path)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: SET_TOTAL,
          total: data.total,
        })

        if (page) {
          dispatch({
            type: APPEND_NEWS,
            news: data.news,
          })
        } else {
          dispatch({
            type: SET_NEWS,
            news: data.news,
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getNewsCategories = (resolve) => {
    fetch(`${state.root}/sites/news_categories.json`)
      .then((response) => response.json())
      .then((categories) => resolve(categories))
      .catch((error) => {
        console.error(error)
        resolve([])
      })
  }

  const clearTotal = () => {
    dispatch({
      type: SET_TOTAL,
      total: 0,
    })
  }

  const getPost = (id, site, news, preview) => {
    fetch(
      `${state.root}/sites/post.json?slug=${id}&site=${site}&news=${news}&preview=${preview}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.post) {
          dispatch({
            type: SET_POST,
            post: data.post,
          })
        } else {
          history.push('/404')
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getResults = (site, query) => {
    fetch(`${state.root}/search.json?site=${site}&q=${query}`)
      .then((response) => response.json())
      .then((data) => {
        dispatch({
          type: SET_RESULTS,
          results: data,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <SiteContext.Provider
      value={{
        site: state.site,
        page: state.page,
        race: state.race,
        events: state.events,
        event: state.event,
        featuredNews: state.featuredNews,
        news: state.news,
        total: state.total,
        post: state.post,
        results: state.results,
        root: state.root,
        getSite,
        getHomepage,
        getPage,
        getRace,
        getEvents,
        getEventCategories,
        getEvent,
        getFeaturedNews,
        getNews,
        getNewsCategories,
        clearTotal,
        getPost,
        getResults,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SiteProvider
